import React, { useState, useEffect } from 'react';
import { Card } from '../ui/card';
import { ArrowLeft, Activity, Calendar, Monitor, LineChart, BarChart } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Line, Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

// เพิ่ม Skeleton Loading Components
const MetricCardSkeleton = () => (
  <div className="p-2 md:p-4 bg-white border rounded-lg animate-pulse">
    <div className="flex flex-col md:flex-row items-center">
      <div className="rounded-full bg-gray-200 p-1.5 md:p-3 md:mr-4 mb-1 md:mb-0 h-6 w-6 md:h-12 md:w-12"></div>
      <div className="text-center md:text-left">
        <div className="h-3 w-20 md:h-4 md:w-24 bg-gray-200 rounded mb-1 md:mb-2"></div>
        <div className="h-4 w-12 md:h-6 md:w-16 bg-gray-200 rounded"></div>
      </div>
    </div>
  </div>
);

const ChartSkeleton = () => (
  <div className="p-4 md:p-6 bg-white border rounded-lg animate-pulse">
    <div className="h-5 w-40 bg-gray-200 rounded mb-4"></div>
    <div className="h-60 md:h-80 bg-gray-100 rounded-lg flex items-center justify-center">
      <div className="text-gray-400 flex flex-col items-center">
        <svg className="w-12 h-12 text-gray-200" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512">
          <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/>
        </svg>
        <div className="h-4 w-32 bg-gray-200 rounded mt-4"></div>
      </div>
    </div>
  </div>
);

const PieChartSkeleton = () => (
  <div className="p-6 bg-white border rounded-lg animate-pulse">
    <div className="h-5 w-40 bg-gray-200 rounded mb-4"></div>
    <div className="h-72 flex justify-center">
      <div className="w-full max-w-md flex items-center justify-center">
        <div className="flex flex-col items-center">
          <div className="w-40 h-40 rounded-full bg-gray-200"></div>
          <div className="h-4 w-32 bg-gray-200 rounded mt-4"></div>
        </div>
      </div>
    </div>
    <div className="mt-4">
      <div className="h-4 bg-gray-200 rounded w-full max-w-md mb-2"></div>
      <div className="space-y-2">
        {[...Array(4)].map((_, i) => (
          <div key={i} className="flex items-center justify-between py-2 border-b border-gray-100">
            <div className="flex items-center">
              <div className="w-3 h-3 rounded-full bg-gray-200 mr-2"></div>
              <div className="h-3 w-24 bg-gray-200 rounded"></div>
            </div>
            <div className="flex items-center space-x-4">
              <div className="h-3 w-10 bg-gray-200 rounded"></div>
              <div className="h-3 w-8 bg-gray-200 rounded"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const TableRowSkeleton = () => (
  <tr className="hover:bg-gray-50 animate-pulse">
    <td className="px-4 py-3 whitespace-nowrap">
      <div className="h-4 w-20 bg-gray-200 rounded"></div>
    </td>
    <td className="px-4 py-3 text-right whitespace-nowrap">
      <div className="h-4 w-16 bg-gray-200 rounded ml-auto"></div>
    </td>
    <td className="px-4 py-3 text-right whitespace-nowrap">
      <div className="h-4 w-16 bg-gray-200 rounded ml-auto"></div>
    </td>
    <td className="px-4 py-3 text-right whitespace-nowrap">
      <div className="h-4 w-16 bg-gray-200 rounded ml-auto"></div>
    </td>
    <td className="px-4 py-3 text-right whitespace-nowrap">
      <div className="h-4 w-16 bg-gray-200 rounded ml-auto"></div>
    </td>
    <td className="px-4 py-3 text-right whitespace-nowrap">
      <div className="h-4 w-16 bg-gray-200 rounded ml-auto"></div>
    </td>
    <td className="px-4 py-3 text-right whitespace-nowrap">
      <div className="h-4 w-16 bg-gray-200 rounded ml-auto"></div>
    </td>
  </tr>
);

const MobileCardSkeleton = () => (
  <div className="bg-white p-3 border rounded-lg shadow-sm animate-pulse">
    <div className="h-5 w-24 bg-gray-200 rounded mx-auto mb-2"></div>
    <div className="grid grid-cols-2 gap-2 text-center mb-2">
      {[...Array(4)].map((_, i) => (
        <div key={i} className="p-2 bg-gray-50 rounded flex flex-col">
          <div className="h-3 w-16 bg-gray-200 rounded mx-auto mb-2"></div>
          <div className="h-4 w-8 bg-gray-200 rounded mx-auto"></div>
        </div>
      ))}
    </div>
    <div className="grid grid-cols-2 gap-2 border-t pt-2">
      <div className="p-2 bg-gray-50 rounded flex flex-col">
        <div className="h-3 w-16 bg-gray-200 rounded mx-auto mb-2"></div>
        <div className="h-4 w-8 bg-gray-200 rounded mx-auto"></div>
      </div>
      <div className="p-2 bg-gray-50 rounded flex flex-col">
        <div className="h-3 w-16 bg-gray-200 rounded mx-auto mb-2"></div>
        <div className="h-4 w-8 bg-gray-200 rounded mx-auto"></div>
      </div>
    </div>
  </div>
);

const TableSkeleton = () => (
  <Card className="p-4 md:p-6 mb-4">
    <div className="hidden sm:block overflow-x-auto">
      <table className="min-w-full">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">วันที่</th>
            <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">กิจกรรมทั้งหมด</th>
            <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">เข้าสู่ระบบ</th>
            <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">ออกจากระบบ</th>
            <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">เข้าชมหน้า</th>
            <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">ผู้ดูแลระบบ</th>
            <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">ผู้ใช้ทั่วไป</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {[...Array(5)].map((_, i) => <TableRowSkeleton key={i} />)}
        </tbody>
      </table>
    </div>
    <div className="sm:hidden space-y-3">
      {[...Array(5)].map((_, i) => <MobileCardSkeleton key={i} />)}
    </div>
  </Card>
);

const MySwal = withReactContent(Swal);

const LogSummaryDashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [summaryData, setSummaryData] = useState({
    dailySummary: [],
    eventTypeSummary: [],
    deviceSummary: []
  });
  
  const [totalStats, setTotalStats] = useState({
    total: 0,
    login: 0,
    logout: 0,
    pageView: 0
  });
  
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          font: {
            family: "'Kanit', sans-serif",
            size: 12
          },
          padding: 20
        }
      },
      tooltip: {
        titleFont: {
          family: "'Kanit', sans-serif",
          size: 14
        },
        bodyFont: {
          family: "'Kanit', sans-serif",
          size: 13
        },
        padding: 10
      }
    }
  };

  const chartColors = [
    'rgba(54, 162, 235, 0.8)',
    'rgba(255, 99, 132, 0.8)',
    'rgba(75, 192, 192, 0.8)',
    'rgba(255, 206, 86, 0.8)',
    'rgba(153, 102, 255, 0.8)',
    'rgba(255, 159, 64, 0.8)',
    'rgba(199, 199, 199, 0.8)',
    'rgba(83, 102, 255, 0.8)',
    'rgba(255, 99, 71, 0.8)',
    'rgba(50, 205, 50, 0.8)',
  ];

  useEffect(() => {
    const token = localStorage.getItem('admin_token');
    if (!token) {
      MySwal.fire({
        title: 'ไม่มีสิทธิ์เข้าถึง',
        text: 'กรุณาเข้าสู่ระบบด้วยบัญชีผู้ดูแลระบบ',
        icon: 'warning',
        confirmButtonText: 'ตกลง'
      }).then(() => {
        navigate('/admin/login');
      });
      return;
    }

    const fetchLogSummary = async () => {
      try {
        setLoading(true);
        const response = await axios.get('/activity/admin/logs/summary', {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        console.log('Log Summary API Response:', response.data);
        
        if (response.data.dailySummary) {
          const totalLogouts = response.data.dailySummary.reduce(
            (sum, item) => Number(sum) + Number(item.logout_count || 0), 0
          );
          console.log('Total logout events:', totalLogouts);
        }
        
        setSummaryData(response.data);
        setError(null);
        
        setTotalStats({
          total: Number(response.data.dailySummary?.reduce((sum, item) => Number(sum) + Number(item.total_logs || 0), 0)) || 0,
          login: Number(response.data.dailySummary?.reduce((sum, item) => Number(sum) + Number(item.login_count || 0), 0)) || 0,
          logout: Number(response.data.dailySummary?.reduce((sum, item) => Number(sum) + Number(item.logout_count || 0), 0)) || 0,
          pageView: Number(response.data.dailySummary?.reduce((sum, item) => Number(sum) + Number(item.page_view_count || 0), 0)) || 0
        });
      } catch (err) {
        console.error('Error fetching log summary:', err);
        setError(`ไม่สามารถดึงข้อมูลสรุป logs ได้: ${err.message}`);
        
        MySwal.fire({
          title: 'เกิดข้อผิดพลาด',
          text: `ไม่สามารถดึงข้อมูลสรุป logs ได้: ${err.message}`,
          icon: 'error',
          confirmButtonText: 'ตกลง'
        });
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 700);
      }
    };

    fetchLogSummary();
  }, [navigate]);

  const formatDate = (dateStr) => {
    if (!dateStr) return '';
    
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    
    const thaiMonths = ['', 'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'];
    
    return `${day} ${thaiMonths[month]}`;
  };

  const dailyActivityData = {
    labels: summaryData.dailySummary?.map(day => formatDate(day.log_date)) || [],
    datasets: [
      {
        label: 'กิจกรรมทั้งหมด',
        data: summaryData.dailySummary?.map(day => day.total_logs) || [],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        tension: 0.3,
        borderWidth: 2,
      },
      {
        label: 'การเข้าสู่ระบบ',
        data: summaryData.dailySummary?.map(day => day.login_count) || [],
        fill: false,
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        tension: 0.3,
        borderWidth: 2,
      },
      {
        label: 'การออกจากระบบ',
        data: summaryData.dailySummary?.map(day => day.logout_count) || [],
        fill: false,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.3,
        borderWidth: 2,
      }
    ]
  };

  const eventTypePieData = {
    labels: summaryData.eventTypeSummary?.map(item => item.event_type) || [],
    datasets: [
      {
        data: summaryData.eventTypeSummary?.map(item => item.total) || [],
        backgroundColor: chartColors,
        borderWidth: 1
      }
    ]
  };

  const deviceTypePieData = {
    labels: summaryData.deviceSummary?.map(item => item.device_type || 'ไม่ระบุ') || [],
    datasets: [
      {
        data: summaryData.deviceSummary?.map(item => item.total) || [],
        backgroundColor: chartColors,
        borderWidth: 1
      }
    ]
  };

  const userTypeData = {
    labels: summaryData.dailySummary?.map(day => formatDate(day.log_date)) || [],
    datasets: [
      {
        label: 'ผู้ดูแลระบบ',
        data: summaryData.dailySummary?.map(day => day.admin_logs) || [],
        backgroundColor: 'rgba(255, 99, 132, 0.7)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: 'ผู้ใช้ทั่วไป',
        data: summaryData.dailySummary?.map(day => day.user_logs) || [],
        backgroundColor: 'rgba(54, 162, 235, 0.7)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      }
    ]
  };

  const getEventTypeDescription = (eventType) => {
    if (!eventType) return 'ไม่ระบุ';
    
    const descriptions = {
      'login': 'การเข้าสู่ระบบ',
      'logout': 'การออกจากระบบ',
      'page_view': 'การเข้าชมหน้า',
      'page_exit': 'การออกจากหน้า',
      'app_exit': 'การปิดแอปพลิเคชัน',
      'app_inactive': 'ไม่มีการใช้งาน (15+ นาที)',
      'app_active': 'กลับมาใช้งานอีกครั้ง',
      'file_upload': 'การอัพโหลดไฟล์',
      'error': 'ข้อผิดพลาด'
    };
    return descriptions[eventType] || eventType;
  };

  const refreshData = () => {
    const token = localStorage.getItem('admin_token');
    if (!token) return;
    
    setLoading(true);
    
    axios.get('/activity/admin/logs/summary', {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => {
      console.log('Refreshed data:', response.data);
      setSummaryData(response.data);
      setError(null);
      
      MySwal.fire({
        title: 'รีเฟรชข้อมูลสำเร็จ',
        text: 'ข้อมูลสรุปถูกอัปเดตเรียบร้อยแล้ว',
        icon: 'success',
        timer: 2000,
        showConfirmButton: false
      });
    })
    .catch(err => {
      console.error('Error refreshing data:', err);
      MySwal.fire({
        title: 'เกิดข้อผิดพลาด',
        text: `ไม่สามารถรีเฟรชข้อมูลได้: ${err.message}`,
        icon: 'error',
        confirmButtonText: 'ตกลง'
      });
    })
    .finally(() => {
      setTimeout(() => {
        setLoading(false);
      }, 700);
    });
  };
  
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState(60);
  
  useEffect(() => {
    let intervalId;
    
    if (autoRefresh) {
      intervalId = setInterval(() => {
        console.log('Auto refreshing data...');
        refreshData();
      }, refreshInterval * 1000);
    }
    
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [autoRefresh, refreshInterval]);

  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          font: {
            family: "'Kanit', sans-serif",
            size: 12
          },
          padding: 15
        }
      },
      tooltip: {
        bodyFont: {
          family: "'Kanit', sans-serif",
          size: 13
        },
        callbacks: {
          label: function(context) {
            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const value = context.raw;
            const percentage = Math.round((value / total) * 100);
            return `${getEventTypeDescription(context.label)}: ${value} (${percentage}%)`;
          }
        }
      }
    }
  };

  if (loading) {
    return (
      <div className="p-4 sm:p-6 lg:p-8 bg-gray-50 min-h-screen">
        {/* Header แสดงปกติ */}
        <div className="flex justify-end items-center">
          <button
            onClick={() => navigate("/admin/dashboard/activity-logs")}
            className="flex gap-2 bg-none text-gray-600 px-4 py-2 mb-5 rounded-md hover:text-gray-800 transition-colors"
          >
            <ArrowLeft className="h-5 w-5" />
            ย้อนกลับ
          </button>
        </div>

        {/* หัวข้อแสดงปกติ */}
        <div className="mb-8 flex justify-center items-center">
          <div className="text-center">
            <h1 className="text-3xl font-bold text-gray-800">สรุปบันทึกกิจกรรมระบบ</h1>
            <p className="text-gray-600 mt-2">ข้อมูลการใช้งานและกิจกรรมในระบบล่าสุด 30 วัน</p>
          </div>
        </div>

        {/* Card สำหรับปุ่มรีเฟรช แสดงปกติ */}
        <Card className="p-3 md:p-4 mb-4 md:mb-6">
          <div className="flex flex-col sm:flex-row justify-between items-center gap-2 md:gap-3">
            {/* ปุ่มรีเฟรชข้อมูล */}
            <button
              disabled={true}
              className="px-3 py-1.5 sm:px-4 sm:py-2 bg-green-600 text-white text-xs sm:text-sm rounded hover:bg-green-700 flex items-center disabled:opacity-50 w-full sm:w-auto justify-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 sm:h-4 sm:w-4 mr-1 sm:mr-2 animate-spin" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
              </svg>
              <span>โหลด...</span>
            </button>
            
            {/* ตัวเลือก auto refresh แสดงปกติ */}
            <div className="flex items-center gap-1 md:gap-2 w-full sm:w-auto">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox h-3 w-3 sm:h-4 sm:w-4 text-indigo-600"
                  disabled
                />
                <span className="ml-1 text-xs sm:text-sm">รีเฟรชอัตโนมัติ</span>
              </label>
              <select 
                className="form-select text-xs sm:text-sm border rounded py-1 px-1"
                disabled
              >
                <option value="30">30 วินาที</option>
              </select>
            </div>
            
            <div className="flex gap-2 w-full sm:w-auto justify-center">
              {/* ปุ่มไปยังหน้ากิจกรรม */}
              <button
                onClick={() => navigate("/admin/dashboard/activity-logs")}
                className="px-3 py-1.5 sm:px-4 sm:py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 flex items-center text-xs sm:text-sm"
              >
                <Activity className="h-3 w-3 sm:h-4 sm:w-4 mr-1 sm:mr-2" />
                <span>ดูกิจกรรมทั้งหมด</span>
              </button>
            </div>
          </div>
        </Card>

        {/* Metric Cards - ส่วนที่โหลดจาก API */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 mb-4 md:mb-6">
          {[...Array(4)].map((_, i) => (
            <MetricCardSkeleton key={i} />
          ))}
        </div>

        {/* Charts - ส่วนที่โหลดจาก API */}
        <div className="grid grid-cols-1 gap-6 mb-6">
          <ChartSkeleton />
          <ChartSkeleton />
        </div>

        {/* Pie Charts - ส่วนที่โหลดจาก API */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <PieChartSkeleton />
          <PieChartSkeleton />
        </div>

        {/* Table - ส่วนที่โหลดจาก API */}
        <TableSkeleton />

        {/* Mobile Cards - ส่วนที่โหลดจาก API */}
        <MobileCardSkeleton />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 sm:p-6 lg:p-8 bg-gray-50 min-h-screen">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
          <h1 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-4 sm:mb-0">สรุปบันทึกกิจกรรม</h1>
          <button
            onClick={() => navigate("/admin/dashboard/activity-logs")}
            className="flex items-center gap-2 text-gray-600 hover:text-gray-800 transition-colors"
          >
            <ArrowLeft className="h-5 w-5" />
            <span>ย้อนกลับ</span>
          </button>
        </div>
        
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">เกิดข้อผิดพลาด!</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 sm:p-6 lg:p-8 bg-gray-50 min-h-screen">
      <div className="flex justify-end items-center">
        <button
          onClick={() => navigate("/admin/dashboard/activity-logs")}
          className="flex gap-2 bg-none text-gray-600 px-4 py-2 mb-5 rounded-md hover:text-gray-800 transition-colors"
        >
          <ArrowLeft className="h-5 w-5" />
          ย้อนกลับ
        </button>
      </div>
      <div className="mb-8 flex justify-center items-center">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-800">สรุปบันทึกกิจกรรมระบบ</h1>
          <p className="text-gray-600 mt-2">ข้อมูลการใช้งานและกิจกรรมในระบบล่าสุด 30 วัน</p>
        </div>
      </div>
      <Card className="p-3 md:p-4 mb-4 md:mb-6">
        <div className="flex flex-col sm:flex-row justify-between items-center gap-2 md:gap-3">
          <button
            onClick={refreshData}
            disabled={loading}
            className="px-3 py-1.5 sm:px-4 sm:py-2 bg-green-600 text-white text-xs sm:text-sm rounded hover:bg-green-700 flex items-center disabled:opacity-50 w-full sm:w-auto justify-center"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className={`h-3 w-3 sm:h-4 sm:w-4 mr-1 sm:mr-2 ${loading ? 'animate-spin' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>
            <span>{loading ? 'โหลด...' : 'รีเฟรชข้อมูล'}</span>
          </button>
          <div className="flex items-center gap-1 md:gap-2 w-full sm:w-auto">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="form-checkbox h-3 w-3 sm:h-4 sm:w-4 text-indigo-600"
                checked={autoRefresh}
                onChange={e => setAutoRefresh(e.target.checked)}
              />
              <span className="ml-1 text-xs sm:text-sm">รีเฟรชอัตโนมัติ</span>
            </label>
            {autoRefresh && (
              <select 
                className="form-select text-xs sm:text-sm border rounded py-1 px-1"
                value={refreshInterval}
                onChange={e => setRefreshInterval(parseInt(e.target.value))}
                disabled={!autoRefresh}
              >
                <option value="30">30 วินาที</option>
                <option value="60">1 นาที</option>
                <option value="300">5 นาที</option>
                <option value="600">10 นาที</option>
              </select>
            )}
          </div>
          <div className="flex gap-2 w-full sm:w-auto justify-center">
            <button
              onClick={() => navigate("/admin/dashboard/activity-logs")}
              className="px-3 py-1.5 sm:px-4 sm:py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 flex items-center text-xs sm:text-sm"
            >
              <Activity className="h-3 w-3 sm:h-4 sm:w-4 mr-1 sm:mr-2" />
              <span>ดูกิจกรรมทั้งหมด</span>
            </button>
          </div>
        </div>
      </Card>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 mb-4 md:mb-6">
        <Card className="p-2 md:p-4 flex flex-col md:flex-row items-center">
          <div className="rounded-full bg-blue-100 p-1.5 md:p-3 md:mr-4 mb-1 md:mb-0">
            <Activity className="h-3 w-3 md:h-6 md:w-6 text-blue-600" />
          </div>
          <div className="text-center md:text-left">
            <div className="text-xs md:text-sm font-medium text-gray-500">กิจกรรมทั้งหมด</div>
            <div className="text-sm md:text-2xl font-bold text-gray-800">
              {(totalStats.total || 0).toLocaleString()}
            </div>
          </div>
        </Card>
        <Card className="p-2 md:p-4 flex flex-col md:flex-row items-center">
          <div className="rounded-full bg-green-100 p-1.5 md:p-3 md:mr-4 mb-1 md:mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 md:h-6 md:w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
            </svg>
          </div>
          <div className="text-center md:text-left">
            <div className="text-xs md:text-sm font-medium text-gray-500">การเข้าสู่ระบบ</div>
            <div className="text-sm md:text-2xl font-bold text-gray-800">{totalStats.login.toLocaleString()}</div>
          </div>
        </Card>
        <Card className="p-2 md:p-4 flex flex-col md:flex-row items-center">
          <div className="rounded-full bg-yellow-100 p-1.5 md:p-3 md:mr-4 mb-1 md:mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 md:h-6 md:w-6 text-yellow-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>
          </div>
          <div className="text-center md:text-left">
            <div className="text-xs md:text-sm font-medium text-gray-500">การออกจากระบบ</div>
            <div className="text-sm md:text-2xl font-bold text-gray-800">{totalStats.logout.toLocaleString()}</div>
          </div>
        </Card>
        <Card className="p-2 md:p-4 flex flex-col md:flex-row items-center">
          <div className="rounded-full bg-indigo-100 p-1.5 md:p-3 md:mr-4 mb-1 md:mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 md:h-6 md:w-6 text-indigo-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
          </div>
          <div className="text-center md:text-left">
            <div className="text-xs md:text-sm font-medium text-gray-500">การเข้าชมหน้า</div>
            <div className="text-sm md:text-2xl font-bold text-gray-800">
              {Number(totalStats.pageView).toLocaleString()}
            </div>
          </div>
        </Card>
      </div>
      <div className="grid grid-cols-1 gap-6 mb-6">
        <Card className="p-4 md:p-6">
          <h3 className="text-base md:text-lg font-semibold mb-3 md:mb-4 flex items-center">
            <LineChart className="h-4 w-4 md:h-5 md:w-5 mr-2 text-indigo-600" />
            <span>กิจกรรมรายวัน (30 วันล่าสุด)</span>
          </h3>
          <div className="h-60 md:h-80">
            <Line 
              data={dailyActivityData}
              options={{
                ...chartOptions,
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                  x: {
                    title: {
                      display: false,
                      text: 'วันที่',
                      font: {
                        family: "'Kanit', sans-serif",
                        size: 12
                      }
                    },
                    ticks: {
                      font: {
                        family: "'Kanit', sans-serif",
                        size: window.innerWidth < 768 ? 8 : 12
                      },
                      maxRotation: 90,
                      autoSkip: true,
                      maxTicksLimit: window.innerWidth < 768 ? 6 : 15
                    }
                  },
                  y: {
                    title: {
                      display: false,
                      text: 'จำนวนกิจกรรม',
                      font: {
                        family: "'Kanit', sans-serif",
                        size: 12
                      }
                    },
                    beginAtZero: true,
                    ticks: {
                      precision: 0,
                      font: {
                        family: "'Kanit', sans-serif",
                        size: window.innerWidth < 768 ? 8 : 12
                      }
                    }
                  }
                },
                plugins: {
                  legend: {
                    position: window.innerWidth < 768 ? 'bottom' : 'right',
                    labels: {
                      boxWidth: window.innerWidth < 768 ? 8 : 12,
                      padding: window.innerWidth < 768 ? 10 : 20,
                      font: {
                        family: "'Kanit', sans-serif",
                        size: window.innerWidth < 768 ? 8 : 12
                      }
                    }
                  }
                }
              }}
            />
          </div>
        </Card>
        <Card className="p-4 md:p-6">
          <h3 className="text-base md:text-lg font-semibold mb-3 md:mb-4 flex items-center">
            <BarChart className="h-4 w-4 md:h-5 md:w-5 mr-2 text-indigo-600" />
            <span>เปรียบเทียบกิจกรรมตามประเภทผู้ใช้</span>
          </h3>
          <div className="h-60 md:h-80">
            <Bar
              data={userTypeData}
              options={{
                ...chartOptions,
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                  x: {
                    title: {
                      display: false,
                      text: 'วันที่',
                      font: {
                        family: "'Kanit', sans-serif",
                        size: 12
                      }
                    },
                    ticks: {
                      font: {
                        family: "'Kanit', sans-serif",
                        size: window.innerWidth < 768 ? 8 : 12
                      },
                      maxRotation: 90,
                      autoSkip: true,
                      maxTicksLimit: window.innerWidth < 768 ? 6 : 15
                    }
                  },
                  y: {
                    title: {
                      display: false,
                      text: 'จำนวนกิจกรรม',
                      font: {
                        family: "'Kanit', sans-serif",
                        size: 12
                      }
                    },
                    beginAtZero: true,
                    ticks: {
                      precision: 0,
                      font: {
                        family: "'Kanit', sans-serif",
                        size: window.innerWidth < 768 ? 8 : 12
                      }
                    }
                  }
                },
                plugins: {
                  legend: {
                    position: window.innerWidth < 768 ? 'bottom' : 'right',
                    labels: {
                      boxWidth: window.innerWidth < 768 ? 8 : 12,
                      padding: window.innerWidth < 768 ? 10 : 20,
                      font: {
                        family: "'Kanit', sans-serif",
                        size: window.innerWidth < 768 ? 8 : 12
                      }
                    }
                  }
                }
              }}
            />
          </div>
        </Card>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <Card className="p-6">
          <h3 className="text-lg font-semibold mb-4 flex items-center">
            <Activity className="h-5 w-5 mr-2 text-indigo-600" />
            <span>สัดส่วนประเภทกิจกรรม</span>
          </h3>
          <div className="h-72 flex justify-center">
            <div className="w-full max-w-md">
              {summaryData.eventTypeSummary?.length > 0 ? (
                <Pie data={eventTypePieData} options={pieOptions} />
              ) : (
                <div className="flex items-center justify-center h-full">
                  <div className="text-gray-500 text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto mb-2 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                    <p>ไม่พบข้อมูลกิจกรรม</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          {summaryData.eventTypeSummary?.length > 0 && (
            <div className="mt-4">
              <table className="w-full text-sm">
                <thead>
                  <tr className="border-b">
                    <th className="text-left py-2">ประเภทกิจกรรม</th>
                    <th className="text-right py-2">จำนวน</th>
                    <th className="text-right py-2">ร้อยละ</th>
                  </tr>
                </thead>
                <tbody>
                  {(summaryData.eventTypeSummary || []).map((item, index) => (
                    <tr key={index} className="border-b border-gray-100">
                      <td className="py-2 flex items-center">
                        <span 
                          className="inline-block w-3 h-3 mr-2 rounded-full" 
                          style={{backgroundColor: chartColors[index % chartColors.length]}}
                        ></span>
                        {item.event_type}
                      </td>
                      <td className="text-right py-2">{item.total.toLocaleString()}</td>
                      <td className="text-right py-2">{item.percentage}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Card>
        <Card className="p-6">
          <h3 className="text-lg font-semibold mb-4 flex items-center">
            <Monitor className="h-5 w-5 mr-2 text-indigo-600" />
            <span>สัดส่วนประเภทอุปกรณ์</span>
          </h3>
          <div className="h-72 flex justify-center">
            <div className="w-full max-w-md">
              <Pie 
                data={deviceTypePieData}
                options={chartOptions}
              />
            </div>
          </div>
          {summaryData.deviceSummary?.length > 0 && (
            <div className="mt-4">
              <table className="w-full text-sm">
                <thead>
                  <tr className="border-b">
                    <th className="text-left py-2">ประเภทอุปกรณ์</th>
                    <th className="text-right py-2">จำนวน</th>
                    <th className="text-right py-2">ร้อยละ</th>
                  </tr>
                </thead>
                <tbody>
                  {summaryData.deviceSummary.map((item, index) => (
                    <tr key={index} className="border-b border-gray-100">
                      <td className="py-2 flex items-center">
                        <span 
                          className="inline-block w-3 h-3 mr-2 rounded-full" 
                          style={{backgroundColor: chartColors[index % chartColors.length]}}
                        ></span>
                        {item.device_type || 'ไม่ระบุ'}
                      </td>
                      <td className="text-right py-2">{item.total.toLocaleString()}</td>
                      <td className="text-right py-2">{item.percentage}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Card>
      </div>
      <Card className="p-4 md:p-6 mb-4">
        <h3 className="text-base md:text-lg font-semibold mb-3 md:mb-4 flex items-center">
          <Calendar className="h-4 w-4 md:h-5 md:w-5 mr-2 text-indigo-600" />
          <span>ข้อมูลกิจกรรมรายวัน</span>
        </h3>
        <div className="hidden sm:block overflow-x-auto">
          <table className="min-w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">วันที่</th>
                <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">กิจกรรมทั้งหมด</th>
                <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">เข้าสู่ระบบ</th>
                <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">ออกจากระบบ</th>
                <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">เข้าชมหน้า</th>
                <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">ผู้ดูแลระบบ</th>
                <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">ผู้ใช้ทั่วไป</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {summaryData.dailySummary?.length > 0 ? (
                summaryData.dailySummary.map((day, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-4 py-3 whitespace-nowrap font-medium text-gray-900">
                      {formatDate(day.log_date)}
                    </td>
                    <td className="px-4 py-3 text-right whitespace-nowrap text-gray-700">
                      {day.total_logs.toLocaleString()}
                    </td>
                    <td className="px-4 py-3 text-right whitespace-nowrap text-gray-700">
                      {day.login_count.toLocaleString()}
                    </td>
                    <td className="px-4 py-3 text-right whitespace-nowrap text-gray-700">
                      {day.logout_count.toLocaleString()}
                    </td>
                    <td className="px-4 py-3 text-right whitespace-nowrap text-gray-700">
                      {day.page_view_count.toLocaleString()}
                    </td>
                    <td className="px-4 py-3 text-right whitespace-nowrap text-gray-700">
                      {day.admin_logs.toLocaleString()}
                    </td>
                    <td className="px-4 py-3 text-right whitespace-nowrap text-gray-700">
                      {day.user_logs.toLocaleString()}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="px-4 py-6 text-center text-gray-500">
                    ไม่พบข้อมูลกิจกรรมรายวัน
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="sm:hidden space-y-3">
          <h3 className="text-sm font-medium text-gray-700 mb-2">ข้อมูลกิจกรรมรายวัน</h3>
          {summaryData.dailySummary?.length > 0 ? (
            summaryData.dailySummary.map((day, index) => (
              <div key={index} className="bg-white p-3 border rounded-lg shadow-sm">
                <div className="font-medium text-gray-900 border-b pb-2 mb-2 text-center">
                  {formatDate(day.log_date)}
                </div>
                <div className="grid grid-cols-2 gap-2 text-center mb-2">
                  <div className="p-2 bg-gray-50 rounded flex flex-col">
                    <div className="text-gray-500 mb-1">กิจกรรมทั้งหมด</div>
                    <div className="font-semibold text-center">{day.total_logs.toLocaleString()}</div>
                  </div>
                  <div className="p-2 bg-gray-50 rounded flex flex-col">
                    <div className="text-gray-500 mb-1">เข้าสู่ระบบ</div>
                    <div className="font-semibold text-center">{day.login_count.toLocaleString()}</div>
                  </div>
                  <div className="p-2 bg-gray-50 rounded flex flex-col">
                    <div className="text-gray-500 mb-1">ออกจากระบบ</div>
                    <div className="font-semibold text-center">{day.logout_count.toLocaleString()}</div>
                  </div>
                  <div className="p-2 bg-gray-50 rounded flex flex-col">
                    <div className="text-gray-500 mb-1">เข้าชมหน้า</div>
                    <div className="font-semibold text-center">{day.page_view_count.toLocaleString()}</div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-2 text-sm border-t pt-2">
                  <div className="p-2 bg-blue-50 rounded flex flex-col">
                    <div className="text-gray-600 mb-1">ผู้ดูแลระบบ</div>
                    <div className="font-semibold text-center text-blue-700">{day.admin_logs.toLocaleString()}</div>
                  </div>
                  <div className="p-2 bg-green-50 rounded flex flex-col">
                    <div className="text-gray-600 mb-1">ผู้ใช้ทั่วไป</div>
                    <div className="font-semibold text-center text-green-700">{day.user_logs.toLocaleString()}</div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="py-4 text-center text-gray-500 bg-white rounded-lg border">
              ไม่พบข้อมูลกิจกรรมรายวัน
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default LogSummaryDashboard;