import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './input.css';
import './styles/markdown.css';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// แสดงข้อความเมื่อ root element ถูก render เรียบร้อยแล้ว
console.log('✅ React Application ถูกเรนเดอร์เรียบร้อยแล้ว');

reportWebVitals();
// serviceWorkerRegistration.register();