import React, { useState, useEffect, useCallback } from 'react';
import { Card } from "../ui/card";
import { Plus, Search, Edit2, Trash2, ArrowLeft, MoveVertical } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const MySwal = withReactContent(Swal);

// เพิ่ม Skeleton Components

// Table Row Skeleton
const TableRowSkeleton = () => (
  <div className="flex items-center border-b animate-pulse">
    <div className="w-[10%] px-3 py-4 text-center">
      <div className="flex justify-center">
        <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
      </div>
    </div>
    <div className="w-[15%] px-3 py-4 text-center">
      <div className="h-4 w-16 bg-gray-200 rounded mx-auto"></div>
    </div>
    <div className="w-[40%] px-3 py-4">
      <div className="h-4 w-3/4 bg-gray-200 rounded"></div>
    </div>
    <div className="w-[15%] px-3 py-4 text-center">
      <div className="h-5 w-20 bg-gray-200 rounded mx-auto"></div>
    </div>
    <div className="w-[20%] px-3 py-4 text-center flex justify-center">
      <div className="h-5 w-5 bg-gray-200 rounded-full mr-3"></div>
      <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
    </div>
  </div>
);

// Mobile Card Skeleton
const MobileCardSkeleton = () => (
  <div className="border rounded-lg overflow-hidden animate-pulse">
    <div className="p-3 bg-gray-50 border-b flex justify-between items-center">
      <div className="h-4 w-24 bg-gray-200 rounded"></div>
      <div className="h-4 w-5 bg-gray-200 rounded-full"></div>
    </div>
    <div className="p-3">
      <div className="h-5 w-3/4 bg-gray-200 rounded mb-2"></div>
      <div className="h-4 w-1/2 bg-gray-200 rounded"></div>
    </div>
    <div className="p-3 bg-gray-50 border-t flex justify-end space-x-2">
      <div className="h-8 w-16 bg-gray-200 rounded"></div>
      <div className="h-8 w-12 bg-gray-200 rounded"></div>
    </div>
  </div>
);

// Pending Report Card Skeleton
const PendingReportSkeleton = () => (
  <div className="flex items-center border-b animate-pulse">
    <div className="w-[15%] px-4 py-4 text-center">
      <div className="h-4 w-16 bg-gray-200 rounded mx-auto"></div>
    </div>
    <div className="w-[30%] px-4 py-4">
      <div className="h-4 w-3/4 bg-gray-200 rounded"></div>
    </div>
    <div className="w-[15%] px-4 py-4 text-center">
      <div className="h-4 w-20 bg-gray-200 rounded mx-auto"></div>
    </div>
    <div className="w-[25%] px-4 py-4">
      <div className="h-4 w-full bg-gray-200 rounded mb-2"></div>
      <div className="h-3 w-2/3 bg-gray-200 rounded"></div>
    </div>
    <div className="w-[15%] px-4 py-4 text-center flex justify-center">
      <div className="h-5 w-5 bg-gray-200 rounded-full mr-3"></div>
      <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
    </div>
  </div>
);

// Mobile Pending Report Skeleton
const MobilePendingReportSkeleton = () => (
  <div className="border rounded-lg overflow-hidden bg-white animate-pulse">
    <div className="p-3 bg-gray-50 border-b flex justify-between items-center">
      <div className="h-4 w-24 bg-gray-200 rounded"></div>
      <div className="h-4 w-24 bg-gray-200 rounded"></div>
    </div>
    <div className="p-3">
      <div className="h-5 w-3/4 bg-gray-200 rounded mb-2"></div>
      <div className="h-4 w-full bg-gray-200 rounded mb-2"></div>
      <div className="h-3 w-2/3 bg-gray-200 rounded"></div>
    </div>
    <div className="p-3 bg-gray-50 border-t flex justify-end space-x-2">
      <div className="h-8 w-16 bg-gray-200 rounded"></div>
      <div className="h-8 w-16 bg-gray-200 rounded"></div>
    </div>
  </div>
);

const ReportStatusManagement = () => {
  const [reportStatuses, setReportStatuses] = useState([]);
  const [userReports, setUserReports] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editingStatus, setEditingStatus] = useState(null);
  const [formData, setFormData] = useState({
    date: '',
    title: '',
    status: 'รอดำเนินการ',
    description: ''
  });
  const [isOrderChanged, setIsOrderChanged] = useState(false);
  const [activeTab, setActiveTab] = useState('existing');  // 'existing' หรือ 'pending'
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // ตรวจสอบว่ามี admin_token หรือไม่
  useEffect(() => {
    const admin_token = localStorage.getItem("admin_token");
    if (!admin_token) {
      MySwal.fire({
        title: 'ไม่พบข้อมูลการเข้าสู่ระบบของแอดมิน',
        text: 'กรุณาเข้าสู่ระบบด้วยบัญชีแอดมิน',
        icon: 'warning',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#3085d6'
      }).then(() => {
        navigate('/admin/login');
      });
    }
  }, [navigate]);

  // Fetch report statuses from API
  const fetchReportStatuses = useCallback(async () => {
    try {
      setLoading(true); // เริ่มต้น loading
      const admin_token = localStorage.getItem("admin_token");
      
      const response = await axios.get('/problem_status', {
        headers: {
          'Authorization': `Bearer ${admin_token}`,
          'Content-Type': 'application/json'
        }
      });
      
      const sortedStatuses = [...response.data].sort((a, b) => {
        if (a.order_position !== undefined && b.order_position !== undefined) {
          return b.order_position - a.order_position; 
        }
        return b.id - a.id;
      });
      setReportStatuses(sortedStatuses);
    } catch (error) {
      console.error('Error fetching report statuses:', error);
      
      if (error.response && error.response.status === 401) {
        MySwal.fire({
          title: 'เซสชันหมดอายุ!',
          text: 'กรุณาเข้าสู่ระบบแอดมินใหม่อีกครั้ง',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          localStorage.removeItem("admin_token");
          navigate('/admin/login');
        });
      }
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 700);
    }
  }, [navigate]);

  // Fetch user reports from API
  const fetchUserReports = useCallback(async () => {
    try {
      setLoading(true); // เริ่มต้น loading
      const admin_token = localStorage.getItem("admin_token");
      
      const response = await axios.get('/report_by_users', {
        headers: {
          'Authorization': `Bearer ${admin_token}`,
          'Content-Type': 'application/json'
        }
      });
      setUserReports(response.data);
    } catch (error) {
      console.error('Error fetching user reports:', error);
      
      if (error.response && error.response.status === 401) {
        MySwal.fire({
          title: 'เซสชันหมดอายุ!',
          text: 'กรุณาเข้าสู่ระบบแอดมินใหม่อีกครั้ง',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          localStorage.removeItem("admin_token");
          navigate('/admin/login');
        });
      }
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 700);
    }
  }, [navigate]);

  useEffect(() => {
    fetchReportStatuses();
    fetchUserReports();
  }, [fetchReportStatuses, fetchUserReports]);

  const resetForm = () => {
    setEditingStatus(null);
    setFormData({
      date: '',
      title: '',
      status: 'รอดำเนินการ',
      description: ''
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const admin_token = localStorage.getItem("admin_token");
      
      if (!admin_token) {
        MySwal.fire({
          title: 'ไม่พบข้อมูลการเข้าสู่ระบบ',
          text: 'กรุณาเข้าสู่ระบบแอดมินก่อนดำเนินการ',
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          navigate('/admin/login');
        });
        return;
      }
      
      let formattedDate;
      if (formData.date) {
        const dateObj = new Date(formData.date);
        formattedDate = format(dateObj, 'yyyy-MM-dd');
      } else {
        formattedDate = format(new Date(), 'yyyy-MM-dd');
      }

      let nextOrderPosition = 1;
      if (reportStatuses.length > 0) {
        const maxOrderPos = Math.max(...reportStatuses.map(item => 
          item.order_position !== null && item.order_position !== undefined ? Number(item.order_position) : 0
        ));
        nextOrderPosition = maxOrderPos + 1;
      }

      const submissionData = { 
        date: formattedDate,
        title: formData.title,
        status: formData.status,
        description: formData.description || '',
        order_position: editingStatus ? (editingStatus.order_position || 0) : nextOrderPosition
      };

      const url = editingStatus
        ? `/problem_status/${editingStatus.id}`
        : '/problem_status';

      const method = editingStatus ? 'PUT' : 'POST';

      const response = await axios({
        method,
        url,
        data: submissionData,
        headers: {
          'Authorization': `Bearer ${admin_token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200 || response.status === 201) {
        fetchReportStatuses();
        setIsFormVisible(false);
        resetForm();
        MySwal.fire({
          title: 'สำเร็จ!',
          text: 'บันทึกสถานะปัญหาเรียบร้อยแล้ว.',
          icon: 'success',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#3085d6'
        });
      } else {
        MySwal.fire('เกิดข้อผิดพลาด!', 'ไม่สามารถบันทึกสถานะปัญหาได้.', 'error');
      }
    } catch (error) {
      console.error('Error saving report status:', error);
      
      if (error.response && error.response.status === 401) {
        MySwal.fire({
          title: 'เซสชันหมดอายุหรือไม่มีสิทธิ์',
          text: 'กรุณาเข้าสู่ระบบแอดมินใหม่',
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          localStorage.removeItem("admin_token");
          navigate('/admin/login');
        });
        return;
      }
      
      const errorMessage = error.response?.data?.message || 'ไม่สามารถบันทึกสถานะปัญหาได้';
      MySwal.fire('เกิดข้อผิดพลาด!', errorMessage, 'error');
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 700);
    }
  };

  const handleDelete = async (id) => {
    MySwal.fire({
      title: 'คุณแน่ใจหรือไม่?',
      text: 'คุณจะไม่สามารถย้อนกลับได้!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ใช่, ลบเลย!',
      cancelButtonText: 'ยกเลิก'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const admin_token = localStorage.getItem("admin_token");
          
          const response = await axios.delete(`/problem_status/${id}`, {
            headers: {
              'Authorization': `Bearer ${admin_token}`,
              'Content-Type': 'application/json'
            }
          });
          
          if (response.status === 200) {
            fetchReportStatuses();
            MySwal.fire({
              title: 'ลบแล้ว!',
              text: 'สถานะปัญหาถูกลบเรียบร้อยแล้ว.',
              confirmButtonColor: '#3085d6',
              icon: 'success',
              confirmButtonText: 'ตกลง'
            });
          }
        } catch (error) {
          console.error('Error deleting report status:', error);
          
          if (error.response && error.response.status === 401) {
            MySwal.fire({
              title: 'เซสชันหมดอายุหรือไม่มีสิทธิ์',
              text: 'กรุณาเข้าสู่ระบบแอดมินใหม่',
              icon: 'error',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'ตกลง'
            }).then(() => {
              localStorage.removeItem("admin_token");
              navigate('/admin/login');
            });
            return;
          }
          
          const errorMessage = error.response?.data?.message || 'ไม่สามารถลบสถานะปัญหาได้';
          MySwal.fire('เกิดข้อผิดพลาด!', errorMessage, 'error');
          
        } finally {
          setTimeout(() => {
            setLoading(false);
          }, 700);
        }
      }
    });
  };

  const handleEdit = (status) => {
    setEditingStatus(status);
    setFormData({
      date: new Date(status.date),
      title: status.title,
      status: status.status,
      description: status.description || ''
    });
    setIsFormVisible(true);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    
    const items = Array.from(reportStatuses);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    
    const maxOrder = items.length;
    const updatedItems = items.map((item, index) => ({
      ...item,
      order_position: maxOrder - index
    }));
    
    setReportStatuses(updatedItems);
    setIsOrderChanged(true);
  };

  const handleSaveOrder = async () => {
    try {
      setLoading(true);
      const admin_token = localStorage.getItem("admin_token");
      
      const orderData = reportStatuses.map((item, index) => ({
        id: item.id,
        order_position: reportStatuses.length - index
      }));

      const response = await axios.post('/problem_status/reorder', {
        reportStatuses: orderData
      }, {
        headers: {
          'Authorization': `Bearer ${admin_token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        setIsOrderChanged(false);
        MySwal.fire({
          title: 'สำเร็จ!',
          text: 'บันทึกลำดับสถานะปัญหาเรียบร้อยแล้ว.',
          icon: 'success',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#3085d6'
        });
      }
    } catch (error) {
      console.error('Error saving order:', error);
      
      if (error.response && error.response.status === 401) {
        MySwal.fire({
          title: 'เซสชันหมดอายุหรือไม่มีสิทธิ์',
          text: 'กรุณาเข้าสู่ระบบแอดมินใหม่',
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          localStorage.removeItem("admin_token");
          navigate('/admin/login');
        });
        return;
      }
      
      MySwal.fire('เกิดข้อผิดพลาด!', 'ไม่สามารถบันทึกลำดับสถานะปัญหาได้.', 'error');
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 700);
    }
  };

  const handleApproveUserReport = async (id) => {
    try {
      setLoading(true);
      const admin_token = localStorage.getItem("admin_token");
      
      const response = await axios.post(`/report_by_users/approve/${id}`, {}, {
        headers: {
          'Authorization': `Bearer ${admin_token}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (response.status === 200) {
        MySwal.fire({
          title: 'อนุมัติสำเร็จ!',
          text: 'รายงานปัญหาได้รับการอนุมัติและเพิ่มเข้าสู่ระบบแล้ว',
          icon: 'success',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#3085d6'
        });
        fetchReportStatuses();
        fetchUserReports();
      }
    } catch (error) {
      console.error('Error approving user report:', error);
      
      if (error.response && error.response.status === 401) {
        MySwal.fire({
          title: 'เซสชันหมดอายุหรือไม่มีสิทธิ์',
          text: 'กรุณาเข้าสู่ระบบแอดมินใหม่',
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          localStorage.removeItem("admin_token");
          navigate('/admin/login');
        });
        return;
      }
      
      const errorMessage = error.response?.data?.message || 'ไม่สามารถอนุมัติรายงานปัญหาได้';
      MySwal.fire('เกิดข้อผิดพลาด!', errorMessage, 'error');
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 700);
    }
  };

  const handleRejectUserReport = async (id) => {
    MySwal.fire({
      title: 'คุณแน่ใจหรือไม่?',
      text: 'คุณกำลังจะลบรายงานปัญหานี้ ไม่สามารถย้อนกลับได้!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ใช่, ลบเลย!',
      cancelButtonText: 'ยกเลิก'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const admin_token = localStorage.getItem("admin_token");
          
          const response = await axios.delete(`/report_by_users/${id}`, {
            headers: {
              'Authorization': `Bearer ${admin_token}`,
              'Content-Type': 'application/json'
            }
          });
          
          if (response.status === 200) {
            MySwal.fire({
              title: 'ลบแล้ว!',
              text: 'รายงานปัญหาถูกลบเรียบร้อยแล้ว',
              icon: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'ตกลง'
            });
            fetchUserReports();
          }
        } catch (error) {
          console.error('Error rejecting user report:', error);
          
          if (error.response && error.response.status === 401) {
            MySwal.fire({
              title: 'เซสชันหมดอายุหรือไม่มีสิทธิ์',
              text: 'กรุณาเข้าสู่ระบบแอดมินใหม่',
              icon: 'error',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'ตกลง'
            }).then(() => {
              localStorage.removeItem("admin_token");
              navigate('/admin/login');
            });
            return;
          }
          
          const errorMessage = error.response?.data?.message || 'ไม่สามารถลบรายงานปัญหาได้';
          MySwal.fire('เกิดข้อผิดพลาด!', errorMessage, 'error');
        } finally {
          setTimeout(() => {
            setLoading(false);
          }, 700);
        }
      }
    });
  };

  const filteredStatuses = reportStatuses.filter((status) =>
    status.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  const filteredUserReports = userReports.filter((report) =>
    report.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const statusOptions = ['รอดำเนินการ', 'กำลังดำเนินการ','เสร็จสิ้น'];

  return (
    <div className="p-4 sm:p-8 bg-gray-50 min-h-screen">
      <div className="flex justify-end items-center">
        <button
          onClick={() => navigate('/admin/dashboard/data_management')}
          className="flex gap-2 bg-none text-gray-600 px-4 py-2 mb-5 rounded-md hover:text-gray-800 transition-colors"
        >
          <ArrowLeft className="h-5 w-5" />
          ย้อนกลับ
        </button>
      </div>

      <div className="mb-8 flex justify-center items-center">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-800">จัดการสถานะรายงานปัญหา</h1>
          <p className="text-xl text-gray-600 mt-2">เพิ่ม ลบ และแก้ไขสถานะรายงานปัญหา</p>
        </div>
      </div>

      <div className="flex border-b mb-4 sm:mb-6 overflow-x-auto justify-center">
        <button
          className={`py-2 px-3 sm:px-4 whitespace-nowrap text-sm sm:text-base ${activeTab === 'existing' ? 'border-b-2 border-blue-500 text-blue-500 font-medium' : 'text-gray-500'}`}
          onClick={() => setActiveTab('existing')}
        >
          รายการที่มีอยู่ ({reportStatuses.length})
        </button>
        <button
          className={`py-2 px-3 sm:px-4 whitespace-nowrap text-sm sm:text-base ${activeTab === 'pending' ? 'border-b-2 border-blue-500 text-blue-500 font-medium' : 'text-gray-500'}`}
          onClick={() => setActiveTab('pending')}
        >
          รอการอนุมัติ ({userReports.length})
        </button>
      </div>

      <Card className="p-6 mb-6">
        <div className="flex justify-between items-center mb-1">
          <div className="relative w-64">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              placeholder={activeTab === 'existing' ? "ค้นหาสถานะรายงาน..." : "ค้นหารายงานรอการอนุมัติ..."}
              className="pl-10 pr-4 py-2 w-full border rounded-md focus:ring-gray-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="flex gap-2">
            {activeTab === 'existing' && (
              <>
                {isOrderChanged && (
                  <button
                    onClick={handleSaveOrder}
                    className="flex items-center gap-1 bg-green-600 text-white px-2 py-2 sm:px-4 sm:py-2 rounded-md hover:bg-green-700 transition-colors text-xs sm:text-base"
                  >
                    <span className="hidden sm:inline">บันทึกลำดับ</span>
                    <span className="sm:hidden">บันทึก</span>
                  </button>
                )}
                <button
                  onClick={() => {
                    resetForm();
                    setIsFormVisible(true);
                  }}
                  className="flex items-center gap-1 bg-blue-600 text-white px-3 py-2 sm:px-4 sm:py-2 rounded-md hover:bg-blue-700 transition-colors text-xs sm:text-base"
                >
                  <Plus className="h-4 w-4 sm:h-5 sm:w-5" />
                  <span className="hidden sm:inline">เพิ่มรายงาน</span>
                  <span className="sm:hidden">เพิ่ม</span>
                </button>
              </>
            )}
          </div>
        </div>

        {isFormVisible && activeTab === 'existing' && (
          <form onSubmit={handleSubmit} className="mt-4 border p-3 sm:p-4 rounded-md text-start">
            <div className="mb-3 sm:mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">วันที่</label>
              <DatePicker
                selected={formData.date ? new Date(formData.date) : null}
                onChange={(date) => setFormData({ ...formData, date: date })}
                dateFormat="dd/MM/yyyy"
                className="w-full border rounded-md px-3 py-2 text-sm sm:text-base"
                placeholderText="เลือกวันที่..."
                required
              />
            </div>
            <div className="mb-3 sm:mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">เรื่อง</label>
              <input
                type="text"
                className="w-full border rounded-md px-3 py-2 text-sm sm:text-base"
                placeholder="ระบุหัวข้อ..."
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                required
              />
            </div>
            <div className="mb-3 sm:mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">สถานะ</label>
              <select
                className="w-full border rounded-md px-3 py-2 text-sm sm:text-base"
                value={formData.status}
                onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                required
              >
                {statusOptions.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3 sm:mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">รายละเอียด</label>
              <textarea
                className="w-full border rounded-md px-3 py-2 text-sm sm:text-base"
                placeholder="รายละเอียดปัญหา..."
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                required
                rows={4}
              />
            </div>
            <div className="flex gap-2 sm:gap-4">
              <button
                type="submit"
                className="flex-1 bg-green-600 text-white px-3 py-1.5 sm:px-4 sm:py-2 rounded-md hover:bg-green-700 transition-colors text-sm sm:text-base"
              >
                บันทึก
              </button>
              <button
                type="button"
                onClick={() => {
                  setIsFormVisible(false);
                  resetForm();
                }}
                className="flex-1 bg-gray-300 text-gray-800 px-3 py-1.5 sm:px-4 sm:py-2 rounded-md hover:bg-gray-400 transition-colors text-sm sm:text-base"
              >
                ยกเลิก
              </button>
            </div>
          </form>
        )}
      </Card>

      <Card className="p-3 sm:p-6 overflow-hidden">
        {activeTab === 'existing' ? (
          <>
            <div className="hidden sm:block overflow-x-auto">
              <div className="min-w-full">
                <div className="flex bg-gray-50 border-b">
                  <div className="w-[10%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ลำดับ</div>
                  <div className="w-[15%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">วันที่</div>
                  <div className="w-[40%] px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">เรื่อง</div>
                  <div className="w-[15%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">สถานะ</div>
                  <div className="w-[20%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">จัดการ</div>
                </div>
                
                {loading ? (
                  <div className="bg-white">
                    {[...Array(5)].map((_, index) => (
                      <TableRowSkeleton key={index} />
                    ))}
                  </div>
                ) : filteredStatuses.length === 0 ? (
                  <div className="py-4 text-center bg-white">ไม่มีข้อมูลรายงานสถานะ</div>
                ) : (
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="report-status-list" type="REPORT_STATUS">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="bg-white"
                        >
                          {filteredStatuses.map((status, index) => (
                            <Draggable 
                              key={status.id.toString()} 
                              draggableId={status.id.toString()} 
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`flex items-center border-b hover:bg-gray-50 ${snapshot.isDragging ? 'bg-gray-100' : ''}`}
                                >
                                  <div className="w-[10%] px-3 py-4 text-center">
                                    <div className="flex justify-center cursor-move">
                                      <MoveVertical className="h-5 w-5 text-gray-500" />
                                    </div>
                                  </div>
                                  <div className="w-[15%] px-3 py-4 text-center text-sm text-gray-700">
                                    {format(new Date(status.date), 'd/M/yy')}
                                  </div>
                                  <div className="w-[40%] px-3 py-4 text-left text-sm text-gray-700">
                                    <div className="overflow-hidden overflow-ellipsis">
                                      {status.title}
                                    </div>
                                  </div>
                                  <div className="w-[15%] px-3 py-4 text-center">
                                    <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                      status.status === 'เสร็จสิ้น' ? 'bg-green-100 text-green-800' : 
                                      status.status === 'รอดำเนินการ' ? 'bg-red-100 text-red-800' : 
                                      'bg-yellow-100 text-yellow-800'
                                    }`}>
                                      {status.status}
                                    </span>
                                  </div>
                                  <div className="w-[20%] px-3 py-4 text-center flex justify-center">
                                    <button
                                      onClick={() => handleEdit(status)}
                                      className="text-blue-600 hover:text-blue-900 mr-3"
                                    >
                                      <Edit2 className="h-5 w-5" />
                                    </button>
                                    <button
                                      onClick={() => handleDelete(status.id)}
                                      className="text-red-600 hover:text-red-900"
                                    >
                                      <Trash2 className="h-5 w-5" />
                                    </button>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
              </div>
            </div>

            <div className="sm:hidden">
              {loading ? (
                <div className="space-y-3">
                  {[...Array(3)].map((_, index) => (
                    <MobileCardSkeleton key={index} />
                  ))}
                </div>
              ) : filteredStatuses.length === 0 ? (
                <div className="py-4 text-center text-gray-500">ไม่มีข้อมูลรายงานสถานะ</div>
              ) : (
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="mobile-report-status-list" type="MOBILE_REPORT_STATUS">
                    {(provided) => (
                      <div 
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="space-y-3"
                      >
                        {filteredStatuses.map((status, index) => (
                          <Draggable 
                            key={status.id.toString()} 
                            draggableId={`mobile-${status.id}`} 
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className={`border rounded-lg overflow-hidden ${snapshot.isDragging ? 'bg-gray-100' : 'bg-white'}`}
                              >
                                <div className="p-3 bg-gray-50 border-b flex justify-between items-center">
                                  <div className="text-xs font-medium text-gray-600">
                                    {format(new Date(status.date), 'd MMMM yyyy')}
                                  </div>
                                  <div className="flex items-center">
                                    <span className={`px-2 py-1 rounded-full text-xs font-medium mr-2 ${
                                      status.status === 'เสร็จสิ้น' ? 'bg-green-100 text-green-800' : 
                                      status.status === 'รอดำเนินการ' ? 'bg-red-100 text-red-800' : 
                                      'bg-yellow-100 text-yellow-800'
                                    }`}>
                                      {status.status}
                                    </span>
                                    <div {...provided.dragHandleProps} className="cursor-move">
                                      <MoveVertical className="h-4 w-4 text-gray-500" />
                                    </div>
                                  </div>
                                </div>
                                <div className="p-3">
                                  <h3 className="font-medium text-gray-800 mb-2">{status.title}</h3>
                                  {status.description && (
                                    <p className="text-xs text-gray-600 mb-2 line-clamp-2">
                                      {status.description}
                                    </p>
                                  )}
                                </div>
                                <div className="p-3 bg-gray-50 border-t flex justify-end space-x-2">
                                  <button
                                    onClick={() => handleEdit(status)}
                                    className="flex items-center gap-1 bg-blue-50 text-blue-600 hover:bg-blue-100 px-3 py-1 rounded-md text-xs"
                                  >
                                    <Edit2 className="h-3 w-3" />
                                    แก้ไข
                                  </button>
                                  <button
                                    onClick={() => handleDelete(status.id)}
                                    className="flex items-center gap-1 bg-red-50 text-red-600 hover:bg-red-100 px-3 py-1 rounded-md text-xs"
                                  >
                                    <Trash2 className="h-3 w-3" />
                                    ลบ
                                  </button>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="hidden sm:block overflow-x-auto">
              <div className="min-w-full">
                <div className="flex bg-gray-50 border-b">
                  <div className="w-[15%] px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">วันที่</div>
                  <div className="w-[30%] px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">เรื่อง</div>
                  <div className="w-[15%] px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ผู้แจ้ง</div>
                  <div className="w-[25%] px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">รายละเอียด</div>
                  <div className="w-[15%] px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">จัดการ</div>
                </div>
                
                {loading ? (
                  <div className="bg-white">
                    {[...Array(3)].map((_, index) => (
                      <PendingReportSkeleton key={index} />
                    ))}
                  </div>
                ) : filteredUserReports.length === 0 ? (
                  <div className="py-4 text-center bg-white">ไม่มีรายงานรอการอนุมัติ</div>
                ) : (
                  <div className="bg-white">
                    {filteredUserReports.map(report => (
                      <div key={report.id} className="flex items-center border-b hover:bg-gray-50">
                        <div className="w-[15%] px-4 py-4 text-center text-sm text-gray-700">
                          {format(new Date(report.date), 'd/M/yy')}
                        </div>
                        <div className="w-[30%] px-4 py-4 text-left text-sm text-gray-700">
                          <div className="overflow-hidden overflow-ellipsis">
                            {report.title}
                          </div>
                        </div>
                        <div className="w-[15%] px-4 py-4 text-center text-sm text-gray-700">
                          {report.user_name || 'ไม่ระบุ'}
                        </div>
                        <div className="w-[25%] px-4 py-4 text-left text-sm text-gray-700">
                          <div className="line-clamp-2">
                            {report.description}
                          </div>
                        </div>
                        <div className="w-[15%] px-4 py-4 text-center flex justify-center">
                          <button
                            onClick={() => handleApproveUserReport(report.id)}
                            className="text-green-600 hover:text-green-900 mr-3"
                            title="อนุมัติ"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                          </button>
                          <button
                            onClick={() => handleRejectUserReport(report.id)}
                            className="text-red-600 hover:text-red-900"
                            title="ปฏิเสธ"
                          >
                            <Trash2 className="h-5 w-5" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="sm:hidden">
              {loading ? (
                <div className="space-y-3">
                  {[...Array(3)].map((_, index) => (
                    <MobilePendingReportSkeleton key={index} />
                  ))}
                </div>
              ) : filteredUserReports.length === 0 ? (
                <div className="py-4 text-center text-gray-500">ไม่มีรายงานรอการอนุมัติ</div>
              ) : (
                <div className="space-y-3">
                  {filteredUserReports.map(report => (
                    <div key={report.id} className="border rounded-lg overflow-hidden bg-white">
                      <div className="p-3 bg-gray-50 border-b flex justify-between items-center">
                        <div className="text-xs font-medium text-gray-600">
                          {format(new Date(report.date), 'd MMM yyyy')}
                        </div>
                        <div className="text-xs text-gray-500">
                          {report.user_name || 'ไม่ระบุผู้แจ้ง'}
                        </div>
                      </div>
                      <div className="p-3">
                        <h3 className="font-medium text-gray-800 mb-2">{report.title}</h3>
                        <p className="text-xs text-gray-600 line-clamp-3">
                          {report.description}
                        </p>
                      </div>
                      <div className="p-3 bg-gray-50 border-t flex justify-end space-x-2">
                        <button
                          onClick={() => handleApproveUserReport(report.id)}
                          className="flex items-center gap-1 bg-green-50 text-green-600 hover:bg-green-100 px-3 py-1 rounded-md text-xs"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                          อนุมัติ
                        </button>
                        <button
                          onClick={() => handleRejectUserReport(report.id)}
                          className="flex items-center gap-1 bg-red-50 text-red-600 hover:bg-red-100 px-3 py-1 rounded-md text-xs"
                        >
                          <Trash2 className="h-3 w-3" />
                          ปฏิเสธ
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default ReportStatusManagement;