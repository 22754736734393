import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import tippy from 'tippy.js'; // ต้องติดตั้ง tippy.js: npm install tippy.js
import 'tippy.js/dist/tippy.css';
import thLocale from '@fullcalendar/core/locales/th';
import axios from 'axios';
import styles from './Calendar.module.css';

const CustomCalendar = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const calendarRef = useRef(null);

  // ดึงข้อมูลกิจกรรมจาก API
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const response = await axios.get('/events');
        
        // แปลงข้อมูลถ้าจำเป็น
        const formattedEvents = response.data.map(event => {
          // ไม่ต้องทำอะไรกับ end ที่ส่งมาจาก backend
          // เพราะ backend จัดการแล้ว
          return {
            ...event,
            start: new Date(event.start),
            end: event.end ? new Date(event.end) : null
          };
        });
        
        setEvents(formattedEvents);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching events:", err);
        setError('ไม่สามารถโหลดข้อมูลกิจกรรมได้');
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  // แก้ไข cleanup function
  useEffect(() => {
    // เก็บค่า ref เมื่อ component mount เพื่อใช้ใน cleanup
    const currentCalendarRef = calendarRef;

    return () => {
      try {
        if (currentCalendarRef.current && currentCalendarRef.current.getApi) {
          const api = currentCalendarRef.current.getApi();
          if (api) {
            api.destroy();
          }
        }
      } catch (error) {
        console.error('Error destroying calendar:', error);
      }
    };
  }, []);

  // เพิ่มฟังก์ชันสำหรับสร้าง tooltips
  const eventDidMount = (info) => {
    const { event } = info;
    const title = event.title;
    let description = event.extendedProps?.description || '';
    
    let tooltipContent = `<div class="tooltip-title">${title}</div>`;
    
    if (description) {
      tooltipContent += `<div class="tooltip-description">${description}</div>`;
    }
    
    // เพิ่มวันที่ใน tooltip
    const startDate = event.start ? new Date(event.start) : null;
    const endDate = event.end ? new Date(event.end) : null;
    
    if (startDate) {
      const startFormatted = startDate.toLocaleDateString('th-TH', { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
      });
      
      tooltipContent += `<div class="tooltip-date">เริ่ม: ${startFormatted}</div>`;
      
      // แสดงวันสิ้นสุดถ้ามีและไม่ใช่กิจกรรมทั้งวัน
      if (endDate && !event.allDay) {
        const endFormatted = endDate.toLocaleDateString('th-TH', { 
          year: 'numeric', 
          month: 'long', 
          day: 'numeric' 
        });
        
        tooltipContent += `<div class="tooltip-date">สิ้นสุด: ${endFormatted}</div>`;
      }
    }
    
    tippy(info.el, {
      content: tooltipContent,
      allowHTML: true,
      placement: 'top',
      arrow: true,
      delay: [200, 0], // delay before showing tooltip
      duration: [200, 200], // animation duration [show, hide]
      theme: 'light-border'
    });
  };

  // แก้ไข eventContent เพื่อกำหนดว่าแสดงอะไรในแต่ละกิจกรรม
  const eventContent = (eventInfo) => {
    return {
      html: `<div class="event-title-only">${eventInfo.event.title}</div>`
    };
  };

  const handleDateClick = (info) => {
    console.log('คลิกวันที่:', info.dateStr);
  };

  const handleEventClick = (info) => {
    console.log('อีเวนต์:', info.event.title);
  };

  return (
    <div className={styles.calendarContainer}>
      {loading ? (
        <div className="animate-pulse">
          {/* ส่วนหัวของปฏิทิน */}
          <div className="bg-white p-4 rounded-t-lg border-b">
            <div className="flex items-center justify-between">
              <div className="h-6 w-32 bg-gray-200 rounded"></div>
              <div className="flex space-x-2">
                <div className="h-6 w-6 bg-gray-200 rounded"></div>
                <div className="h-6 w-6 bg-gray-200 rounded"></div>
              </div>
            </div>
          </div>
          
          {/* ส่วนเนื้อหาของปฏิทิน */}
          <div className="bg-white p-3 rounded-b-lg">
            {/* แถวชื่อวัน */}
            <div className="grid grid-cols-7 gap-1 mb-2">
              {["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"].map((day, i) => (
                <div key={i} className="h-8 flex items-center justify-center">
                  <div className="h-4 w-4 bg-gray-200 rounded"></div>
                </div>
              ))}
            </div>
            
            {/* แถวตัวเลขวันที่ */}
            {[...Array(5)].map((_, weekIndex) => (
              <div key={weekIndex} className="grid grid-cols-7 gap-1 mb-2">
                {[...Array(7)].map((_, dayIndex) => (
                  <div key={dayIndex} className="h-14 border rounded p-1">
                    <div className="h-4 w-4 bg-gray-200 rounded mb-1.5"></div>
                    {Math.random() > 0.7 && (
                      <div className="h-3 w-full bg-gray-200 rounded-sm"></div>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ) : error ? (
        <div className="text-center py-4 text-sm text-red-500">{error}</div>
      ) : (
        <div className={styles.calendar}>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            locale={thLocale}
            headerToolbar={{
              left: 'title',
              center: '',    
              right: 'prev,next'
            }}
            views={{
              dayGridMonth: {
                titleFormat: { year: 'numeric', month: 'long' }
              }
            }}
            events={events}
            dateClick={handleDateClick}
            eventClick={handleEventClick}
            eventDidMount={eventDidMount}
            eventContent={eventContent}
            height="auto"
            aspectRatio={1.35}
            fixedWeekCount={false}
            showNonCurrentDates={false}
            buttonText={{
              today: 'วันนี้',
              month: 'เดือน',
              week: 'สัปดาห์',
              day: 'วัน'
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CustomCalendar;