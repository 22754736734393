// ไฟล์นี้เป็น UI สำหรับการจัดการไฟล์และโฟลเดอร์ในระบบ(ส่วนหลังบ้าน)

import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "../../ui/card";
import {
  FolderOpen, ArrowLeft, FolderPlus,
  Trash2, Download, RefreshCw, Search,
  Grid, List, Upload, EllipsisVertical
} from "lucide-react";
import { useDropzone } from 'react-dropzone';
import { useFileData } from "./FileDataProvider";
import { getFileIcon, formatFileSize, formatDate, showDialog } from "./FileUtils";
import CreateFolderModal from "../../modals/CreateFolderModal_Backend";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const departments = [
  { id: "acc", label: "Accounting", color: "bg-blue-100 text-blue-800" },
  { id: "fin", label: "Finance", color: "bg-green-100 text-green-800" },
  { id: "wh", label: "Warehouse", color: "bg-yellow-100 text-yellow-800" },
  { id: "hr", label: "Human Resources", color: "bg-purple-100 text-purple-800" },
  { id: "it", label: "Information Technology", color: "bg-red-100 text-red-800" }
];

const renderAccessLevelBadges = (accessLevel) => {
  if (!accessLevel || accessLevel === 'all') {
    return (
      <span className="px-2 py-0.5 text-xs rounded bg-gray-100 text-gray-800 inline-flex items-center h-5">
        <span className="h-2 w-2 bg-gray-400 rounded-full mr-1"></span>
        ทุกแผนก
      </span>
    );
  }

  const accessLevels = accessLevel.split(',');

  return (
    <div className="flex flex-wrap gap-1 justify-start w-full">
      {accessLevels.map(level => {
        const dept = departments.find(d => d.id === level);
        return dept ? (
          <span key={level} className={`px-2 py-0.5 text-xs rounded ${dept.color} inline-flex items-center h-5`}>
            <span className={`h-2 w-2 ${dept.color.includes('blue') ? 'bg-blue-500' :
              dept.color.includes('green') ? 'bg-green-500' :
                dept.color.includes('yellow') ? 'bg-yellow-500' :
                  dept.color.includes('purple') ? 'bg-purple-500' :
                    'bg-red-500'} rounded-full mr-1`}></span>
            {dept.label}
          </span>
        ) : null;
      })}
    </div>
  );
};

// Skeleton Components
const FolderGridSkeleton = () => (
  <div className="animate-pulse">
    <div className="p-4 border rounded-md shadow-sm bg-white">
      <div className="flex items-start justify-between">
        <div className="flex items-center">
          <div className="h-10 w-10 bg-yellow-100 rounded-md flex-shrink-0 mr-3"></div>
          <div className="flex-1">
            <div className="h-5 bg-gray-200 rounded w-24 mb-2"></div>
            <div className="h-3 bg-gray-200 rounded w-16 mb-2"></div>
            <div className="h-5 bg-gray-200 rounded w-20"></div>
          </div>
        </div>
        <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
      </div>
    </div>
  </div>
);

const FolderListSkeleton = () => (
  <div className="flex items-center justify-between p-3 border-b animate-pulse">
    <div className="flex items-center flex-grow">
      <div className="h-6 w-6 bg-yellow-100 rounded-md flex-shrink-0 mr-3"></div>
      <div className="flex-1">
        <div className="h-4 bg-gray-200 rounded w-32 mb-2"></div>
        <div className="h-4 bg-gray-200 rounded w-24"></div>
      </div>
    </div>
    <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
  </div>
);

const FileGridSkeleton = () => (
  <div className="animate-pulse">
    <div className="p-4 border rounded-md shadow-sm bg-white">
      <div className="flex flex-col h-full">
        <div className="flex items-start justify-between mb-3">
          <div className="flex items-center">
            <div className="h-10 w-10 bg-gray-200 rounded flex-shrink-0 mr-3"></div>
            <div>
              <div className="h-4 bg-gray-200 rounded w-32 mb-2"></div>
              <div className="h-3 bg-gray-200 rounded w-16"></div>
            </div>
          </div>
          <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
        </div>
        <div className="h-3 bg-gray-200 rounded w-24 mt-auto"></div>
      </div>
    </div>
  </div>
);

const FileListSkeleton = () => (
  <div className="flex items-center justify-between p-3 border-b animate-pulse">
    <div className="flex items-center flex-grow">
      <div className="h-8 w-8 bg-gray-200 rounded flex-shrink-0 mr-3"></div>
      <div className="flex-grow mr-auto">
        <div className="h-4 bg-gray-200 rounded w-full mb-2"></div>
        <div className="h-3 bg-gray-200 rounded w-32"></div>
      </div>
    </div>
    <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
  </div>
);

const DeletedFolderSkeleton = () => (
  <div className="flex items-center justify-between p-3 border-b animate-pulse border-l-4 border-red-200">
    <div className="flex items-center flex-grow">
      <div className="h-6 w-6 bg-yellow-100 rounded-md flex-shrink-0 mr-3"></div>
      <div>
        <div className="h-4 bg-gray-200 rounded w-32 mb-2"></div>
        <div className="h-3 bg-gray-200 rounded w-24"></div>
      </div>
    </div>
    <div className="flex space-x-2">
      <div className="h-8 w-8 bg-gray-200 rounded"></div>
      <div className="h-8 w-8 bg-gray-200 rounded"></div>
    </div>
  </div>
);

const DeletedFileListSkeleton = () => (
  <div className="flex items-start justify-between p-3 border-b animate-pulse border-l-4 border-red-200">
    <div className="flex items-start flex-grow">
      <div className="h-8 w-8 bg-gray-200 rounded flex-shrink-0 mr-3"></div>
      <div className="flex-grow">
        <div className="h-4 bg-gray-200 rounded w-full mb-2"></div>
        <div className="flex flex-wrap">
          <div className="h-3 bg-gray-200 rounded w-16 mr-3 mb-1"></div>
          <div className="h-3 bg-gray-200 rounded w-24 mr-3 mb-1"></div>
          <div className="h-3 bg-gray-200 rounded w-20 mb-1"></div>
        </div>
      </div>
    </div>
    <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
  </div>
);


const FilesManagementUI = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);

  const {
    loading,
    currentFolderId,
    folderPath,
    searchTerm,
    showDeleted,
    viewMode,
    filteredFolders,
    filteredFiles,
    deletedFolders,
    deletedFiles,
    setSearchTerm,
    setShowDeleted,
    setViewMode,
    setCurrentFolderId,
    setFolderPath,
    fetchFolders,
    fetchFiles,
    fetchDeletedFiles,
    fetchDeletedFolders,
    handleFolderClick,
    handleBack,
    handleCreateFolder: doHandleCreateFolder,
    handleDeleteFile,
    handleRestoreFile,
    handlePermanentDeleteFile,
    handleDeleteFolder,
    handleRestoreFolder,
    handlePermanentDeleteFolder,
    handleDownloadFile,
    handleFileUpload
  } = useFileData();

  const handleCreateFolder = () => {
    console.log('📁 เปิดหน้าต่างสร้างโฟลเดอร์ใหม่');
    setIsCreateFolderModalOpen(true);
  };

  const handleCreateFolderSubmit = (folderName, accessLevel) => {
    console.log(`📁 กำลังสร้างโฟลเดอร์: ${folderName} (สิทธิ์การเข้าถึง: ${accessLevel})`);
    doHandleCreateFolder(folderName, accessLevel);
  };

  const currentFolderAccessLevel = folderPath.length > 0
    ? folderPath[folderPath.length - 1].access_level
    : 'all';

  const handleUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const enhancedHandleFileUpload = (files) => {
    const loading = showDialog({
      title: 'กำลังอัพโหลดไฟล์',
      text: 'โปรดรอสักครู่...',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        showDialog.showLoading();
      }
    });

    handleFileUpload(files, currentFolderId)
      .then(() => {
        loading.close();
        showDialog({
          icon: 'success',
          title: 'สำเร็จ!',
          text: 'อัพโหลดไฟล์เรียบร้อยแล้ว'
        });

        if (currentFolderId) {
          fetchFiles(currentFolderId);
        }
      })
      .catch(error => {
        loading.close();
        showDialog({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: `ไม่สามารถอัพโหลดไฟล์ได้: ${error.message}`
        });
      });
  };

  const enhancedHandlePermanentDeleteFile = (fileId) => {
    MySwal.fire({
      title: 'ยืนยันการลบถาวร',
      text: 'คุณต้องการลบไฟล์นี้อย่างถาวรหรือไม่? การกระทำนี้ไม่สามารถย้อนกลับได้',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'ลบถาวร',
      cancelButtonText: 'ยกเลิก',
      confirmButtonColor: '#d33',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return handlePermanentDeleteFile(fileId)
          .then(() => {
            return { success: true };
          })
          .catch((error) => {
            return { success: false, error: error.message || 'เกิดข้อผิดพลาดในการลบไฟล์' };
          });
      },
      allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.success) {
          MySwal.fire({
            icon: 'success',
            title: 'สำเร็จ!',
            text: 'ลบไฟล์ถาวรเรียบร้อยแล้ว',
            timer: 1500,
            showConfirmButton: false
          });
        } else {
          MySwal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด!',
            text: result.value.error
          });
        }
      }
    });
  };

  const enhancedHandlePermanentDeleteFolder = (folderId) => {
    MySwal.fire({
      title: 'ยืนยันการลบถาวร',
      text: 'คุณต้องการลบโฟลเดอร์นี้อย่างถาวรหรือไม่? การกระทำนี้ไม่สามารถย้อนกลับได้',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'ลบถาวร',
      cancelButtonText: 'ยกเลิก',
      confirmButtonColor: '#d33',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return handlePermanentDeleteFolder(folderId)
          .then(() => {
            return { success: true };
          })
          .catch((error) => {
            return { success: false, error: error.message || 'เกิดข้อผิดพลาดในการลบโฟลเดอร์' };
          });
      },
      allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.success) {
          MySwal.fire({
            icon: 'success',
            title: 'สำเร็จ!',
            text: 'ลบโฟลเดอร์ถาวรเรียบร้อยแล้ว',
            timer: 1500,
            showConfirmButton: false
          }).then(() => {
            fetchDeletedFolders();
          });
        } else {
          MySwal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด!',
            text: result.value.error
          });
        }
      }
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: true,
    maxSize: 20971520,
    accept: {
      'image/*': [],
      'application/pdf': [],
      'application/msword': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'text/plain': [],
      'application/zip': [],
      'application/x-rar-compressed': []
    },
    onDrop: acceptedFiles => {
      if (acceptedFiles?.length) {
        enhancedHandleFileUpload(acceptedFiles);
      }
    },
    onDropRejected: fileRejections => {
      let errorMessage = '';
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach(e => {
          if (e.code === 'file-too-large') {
            errorMessage += `${file.name} มีขนาดใหญ่เกินไป (สูงสุด 20MB)\n`;
          } else if (e.code === 'file-invalid-type') {
            errorMessage += `${file.name} ประเภทไฟล์ไม่รองรับ\n`;
          } else {
            errorMessage += `${file.name}: ${e.message}\n`;
          }
        });
      });

      showDialog({
        icon: 'error',
        title: 'ไม่สามารถอัพโหลดไฟล์ได้',
        text: errorMessage || 'เกิดข้อผิดพลาดในการอัพโหลดไฟล์',
        confirmButtonColor: "#3085d6"
      });
    }
  });

  return (
    <div className="p-8 bg-gray-50 min-h-screen">
      <div {...getRootProps({ className: 'fixed inset-0 pointer-events-none' })}>
        <input {...getInputProps()} ref={fileInputRef} />
      </div>

      {isDragActive && (
        <div className="fixed inset-0 bg-blue-500 bg-opacity-20 z-50 flex flex-col items-center justify-center pointer-events-auto">
          <div className="bg-white rounded-lg shadow-xl p-8 text-center max-w-md animate-fadeIn">
            <Upload className="h-16 w-16 text-blue-500 mx-auto mb-4" />
            <h3 className="text-xl font-semibold text-gray-800 mb-2">วางไฟล์เพื่ออัพโหลด</h3>
            <p className="text-gray-600">ปล่อยไฟล์ที่นี่เพื่ออัพโหลดในโฟลเดอร์ปัจจุบัน</p>
          </div>
        </div>
      )}

      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-gray-800">จัดการไฟล์และโฟลเดอร์</h1>
        <button
          onClick={() => navigate('/admin/dashboard/data_management')}
          className="flex gap-2 bg-gray-100 text-gray-600 px-4 py-2 rounded-md hover:bg-gray-200 transition-colors"
        >
          <ArrowLeft className="h-5 w-5" />
          ย้อนกลับไปหน้าหลัก
        </button>
      </div>

      <div className="flex items-center mb-6 text-base overflow-x-auto">
        <button
          className="text-blue-600 hover:text-blue-800 whitespace-nowrap"
          onClick={() => {
            setCurrentFolderId(null);
            setFolderPath([]);
            fetchFolders();
          }}
        >
          หน้าหลัก
        </button>

        {folderPath.map((folder, index) => (
          <React.Fragment key={folder.id}>
            <span className="mx-2 text-gray-500">/</span>
            <button
              className="text-blue-600 hover:text-blue-800 whitespace-nowrap"
              onClick={() => {
                const newPath = folderPath.slice(0, index + 1);
                setFolderPath(newPath);
                setCurrentFolderId(folder.id);
                fetchFolders(folder.id);
                fetchFiles(folder.id);
              }}
            >
              {folder.name}
            </button>
          </React.Fragment>
        ))}
      </div>

      <Card className="mb-6 p-4">
        <div className="flex flex-wrap gap-3">
          {currentFolderId && (
            <button
              onClick={handleBack}
              className="flex items-center px-4 py-2 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
            >
              <ArrowLeft className="h-4 w-4 mr-2" />
              ย้อนกลับ
            </button>
          )}

          <button
            onClick={handleCreateFolder}
            className="flex items-center px-4 py-2 bg-blue-100 text-blue-700 rounded-md hover:bg-blue-200 transition-colors"
          >
            <FolderPlus className="h-4 w-4 mr-2" />
            สร้างโฟลเดอร์
          </button>

          {currentFolderId && (
            <button
              onClick={handleUpload}
              className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
              disabled={showDeleted}
            >
              <Upload className="h-4 w-4" />
              <span className="font-medium">อัพโหลดไฟล์</span>
            </button>
          )}

          <div className="flex items-center gap-2 ml-auto">
            <button
              onClick={() => setViewMode("grid")}
              className={`p-2 rounded-md transition-colors ${
                viewMode === "grid"
                  ? "bg-blue-100 text-blue-700"
                  : "bg-gray-100 text-gray-600 hover:bg-gray-200"
              }`}
              title="แสดงแบบกริด"
            >
              <Grid className="h-4 w-4" />
            </button>
            <button
              onClick={() => setViewMode("list")}
              className={`p-2 rounded-md transition-colors ${
                viewMode === "list"
                  ? "bg-blue-100 text-blue-700"
                  : "bg-gray-100 text-gray-600 hover:bg-gray-200"
              }`}
              title="แสดงแบบรายการ"
            >
              <List className="h-4 w-4" />
            </button>
          </div>

          <button
            onClick={() => {
              fetchFolders(currentFolderId);
              if (currentFolderId) {
                fetchFiles(currentFolderId);
              }
              if (showDeleted) {
                fetchDeletedFolders();
                fetchDeletedFiles();
              }
            }}
            className="flex items-center px-4 py-2 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
          >
            <RefreshCw className="h-4 w-4 mr-2" />
            รีเฟรช
          </button>

          <button
            onClick={() => {
              setShowDeleted(!showDeleted);
            }}
            className={`flex items-center px-4 py-2 rounded-md transition-colors ${
              showDeleted
                ? "bg-red-500 text-white hover:bg-red-600"
                : "bg-gray-100 text-gray-700 hover:bg-gray-200"
            }`}
          >
            <Trash2 className="h-4 w-4 mr-2" />
            {showDeleted ? "ออกจากถังขยะ" : "ถังขยะ"}
          </button>
        </div>
      </Card>

      <div className="relative mb-6">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
        <input
          type="text"
          placeholder="ค้นหาไฟล์หรือโฟลเดอร์..."
          className="pl-10 pr-4 py-2 w-full border border-gray-300 rounded-md"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {loading ? (
        <>
          <div className="mb-8">
            <h2 className="text-xl font-bold mb-4 text-gray-800">โฟลเดอร์</h2>
            {viewMode === "grid" ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 text-left">
                {[...Array(4)].map((_, index) => (
                  <FolderGridSkeleton key={index} />
                ))}
              </div>
            ) : (
              <Card className="mb-4">
                <div className="divide-y">
                  {[...Array(5)].map((_, index) => (
                    <FolderListSkeleton key={index} />
                  ))}
                </div>
              </Card>
            )}
          </div>

          {currentFolderId && (
            <div>
              <h2 className="text-xl font-bold mb-4 text-gray-800">ไฟล์</h2>
              {viewMode === "grid" ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                  {[...Array(6)].map((_, index) => (
                    <FileGridSkeleton key={index} />
                  ))}
                </div>
              ) : (
                <Card className="mb-4">
                  <div className="divide-y">
                    {[...Array(5)].map((_, index) => (
                      <FileListSkeleton key={index} />
                    ))}
                  </div>
                </Card>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          {!showDeleted && (
            <>
              {filteredFolders.length > 0 && (
                <div className="mb-8">
                  <h2 className="text-xl font-bold mb-4 text-gray-800">โฟลเดอร์</h2>
                  {viewMode === "grid" ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 text-left">
                      {filteredFolders.map(folder => (
                        <Card key={`folder-${folder.id}`} className="p-4 hover:shadow-md transition-shadow">
                          <div className="flex items-start justify-between">
                            <div
                              className="flex items-center cursor-pointer flex-grow"
                              onClick={() => handleFolderClick(folder)}
                            >
                              <FolderOpen className="h-10 w-10 text-yellow-500 mr-3" />
                              <div>
                                <h3 className="font-medium text-gray-800">{folder.name}</h3>
                                <p className="text-xs text-gray-500 mt-1">โฟลเดอร์</p>
                                <div className="mt-2">
                                  {renderAccessLevelBadges(folder.access_level)}
                                </div>
                              </div>
                            </div>

                            <div className="dropdown dropdown-end">
                              <label tabIndex={0} className="btn btn-ghost btn-xs">
                                <EllipsisVertical className="h-5 w-5 text-gray-500 hover:text-gray-700" />
                              </label>
                              <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-md w-40">
                                <li>
                                  <button onClick={() => handleDeleteFolder(folder.id)} className="flex items-center px-3 py-2 hover:bg-gray-100">
                                    <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                                    <span>ลบโฟลเดอร์</span>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Card>
                      ))}
                    </div>
                  ) : (
                    <Card className="mb-4">
                      <div className="divide-y">
                        {filteredFolders.map(folder => (
                          <div key={folder.id} className="flex items-center justify-between p-3 hover:bg-gray-50">
                            <div
                              className="flex items-center cursor-pointer flex-grow"
                              onClick={() => handleFolderClick(folder)}
                            >
                              <FolderOpen className="h-6 w-6 text-yellow-500 mr-3" />
                              <div className="text-start">
                                <span className="font-medium text-gray-800">{folder.name}</span>
                                <div className="mt-1">
                                  {renderAccessLevelBadges(folder.access_level)}
                                </div>
                              </div>
                            </div>

                            <div className="dropdown dropdown-end">
                              <label tabIndex={0} className="btn btn-ghost btn-xs">
                                <EllipsisVertical className="h-5 w-5 text-gray-500 hover:text-gray-700" />
                              </label>
                              <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-md w-40">
                                <li>
                                  <button onClick={() => handleDeleteFolder(folder.id)} className="flex items-center px-3 py-2 hover:bg-gray-100">
                                    <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                                    <span>ลบโฟลเดอร์</span>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Card>
                  )}
                </div>
              )}

              {currentFolderId && (
                <div>
                  <h2 className="text-xl font-bold mb-4 text-gray-800">ไฟล์</h2>
                  {filteredFiles.length > 0 ? (
                    viewMode === "grid" ? (
                      <FilesGridView
                        files={filteredFiles}
                        onDownload={handleDownloadFile}
                        onDelete={handleDeleteFile}
                      />
                    ) : (
                      <FilesListView
                        files={filteredFiles}
                        onDownload={handleDownloadFile}
                        onDelete={handleDeleteFile}
                      />
                    )
                  ) : (
                    <EmptyState message="ไม่มีไฟล์ในโฟลเดอร์นี้" />
                  )}
                </div>
              )}

              {!currentFolderId && filteredFolders.length === 0 && (
                <EmptyState message="ยังไม่มีโฟลเดอร์ในระบบ กรุณาสร้างโฟลเดอร์ใหม่" />
              )}
            </>
          )}

          {showDeleted && (
            <TrashContentWithSkeleton
              loading={false}
              deletedFolders={deletedFolders}
              deletedFiles={deletedFiles}
              viewMode={viewMode}
              onFolderClick={handleFolderClick}
              onRestoreFolder={handleRestoreFolder}
              onPermanentDeleteFolder={enhancedHandlePermanentDeleteFolder}
              onRestoreFile={handleRestoreFile}
              onPermanentDeleteFile={enhancedHandlePermanentDeleteFile}
            />
          )}
        </>
      )}

      <CreateFolderModal
        isOpen={isCreateFolderModalOpen}
        onClose={() => setIsCreateFolderModalOpen(false)}
        onSubmit={handleCreateFolderSubmit}
        parentFolderAccessLevel={currentFolderAccessLevel}
      />
    </div>
  );
};

const EmptyState = ({ message }) => (
  <div className="text-center py-10 bg-gray-50 rounded-md">
    <p className="text-gray-500">{message}</p>
  </div>
);

const FilesGridView = ({ files, onDownload, onDelete }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
    {files.map(file => (
      <Card key={file.id} className="p-4 hover:shadow-md transition-shadow">
        <div className="flex flex-col h-full">
          <div className="flex items-start justify-between text-start mb-3">
            <div className="flex items-center">
              {getFileIcon(file.file_type)}
              <div className="ml-3">
                <h3 className="font-medium text-gray-800 break-all text-left truncate">{file.name}</h3>
                <p className="text-xs text-gray-500">{formatFileSize(file.file_size)}</p>
              </div>
            </div>

            <div className="dropdown dropdown-end">
              <label tabIndex={0} className="btn btn-ghost btn-xs">
                <EllipsisVertical className="h-5 w-5 text-gray-500 hover:text-gray-700" />
              </label>
              <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-md w-40">
                <li>
                  <button onClick={() => onDownload(file.id, file.name)} className="flex items-center px-3 py-2 hover:bg-gray-100">
                    <Download className="h-4 w-4 mr-2 text-blue-500" />
                    <span>ดาวน์โหลด</span>
                  </button>
                </li>
                <li>
                  <button onClick={() => onDelete(file.id)} className="flex items-center px-3 py-2 hover:bg-gray-100">
                    <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                    <span>ลบไฟล์</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <p className="text-xs text-gray-500 mb-4">
            อัพโหลด: {formatDate(file.upload_date)}
          </p>
        </div>
      </Card>
    ))}
  </div>
);

const FilesListView = ({ files, onDownload, onDelete }) => (
  <Card className="mb-4">
    <div className="divide-y">
      {files.map(file => (
        <div key={file.id} className="flex items-center justify-between p-3 hover:bg-gray-50">
          <div className="flex items-center flex-grow">
            <div className="flex-shrink-0 mr-3">
              {getFileIcon(file.file_type)}
            </div>
            <div className="min-w-0 flex-grow mr-auto">
              <p className="font-medium text-gray-800 text-left truncate max-w-full">{file.name}</p>
              <div className="flex text-xs text-gray-500">
                <span className="mr-3">{formatFileSize(file.file_size)}</span>
                <span>อัพโหลด: {formatDate(file.upload_date)}</span>
              </div>
            </div>
          </div>

          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="btn btn-ghost btn-xs">
              <EllipsisVertical className="h-5 w-5 text-gray-500 hover:text-gray-700" />
            </label>
            <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-md w-40">
              <li>
                <button onClick={() => onDownload(file.id, file.name)} className="flex items-center px-3 py-2 hover:bg-gray-100">
                  <Download className="h-4 w-4 mr-2 text-blue-500" />
                  <span>ดาวน์โหลด</span>
                </button>
              </li>
              <li>
                <button onClick={() => onDelete(file.id)} className="flex items-center px-3 py-2 hover:bg-gray-100">
                  <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                  <span>ลบไฟล์</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      ))}
    </div>
  </Card>
);

const TrashContentWithSkeleton = ({
  loading,
  deletedFolders,
  deletedFiles,
  viewMode,
  onFolderClick,
  onRestoreFolder,
  onPermanentDeleteFolder,
  onRestoreFile,
  onPermanentDeleteFile
}) => (
  <div className="mt-8">
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-xl font-bold text-red-600">ถังขยะ</h2>
    </div>

    {loading ? (
      <>
        <div className="mb-8 text-start">
          <h3 className="text-lg font-semibold mb-4">โฟลเดอร์ที่ถูกลบ</h3>
          <Card className="mb-4">
            <div className="divide-y">
              {[...Array(3)].map((_, index) => (
                <DeletedFolderSkeleton key={index} />
              ))}
            </div>
          </Card>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-4">ไฟล์ที่ถูกลบ</h3>
          <Card className="mb-4">
            <div className="divide-y">
              {[...Array(5)].map((_, index) => (
                <DeletedFileListSkeleton key={index} />
              ))}
            </div>
          </Card>
        </div>
      </>
    ) : (
      <>
        {deletedFolders.length > 0 && (
          <div className="mb-8 text-start">
            <h3 className="text-lg font-semibold mb-4">โฟลเดอร์ที่ถูกลบ</h3>
            <Card className="mb-4">
              <div className="divide-y">
                {deletedFolders.map(folder => (
                  <div
                    key={`deleted-folder-${folder.id}`}
                    className="flex items-center justify-between p-3 hover:bg-gray-50 border-l-4 border-red-500"
                  >
                    <div
                      className="flex items-center cursor-pointer flex-grow"
                      onClick={() => onFolderClick(folder)}
                    >
                      <FolderOpen className="h-6 w-6 text-yellow-500 mr-3" />
                      <div>
                        <span className="font-medium text-gray-800">{folder.name}</span>
                        <p className="text-xs text-gray-500">ลบเมื่อ: {formatDate(folder.deleted_at)}</p>
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      <button
                        onClick={() => onRestoreFolder(folder.id)}
                        className="p-2 bg-green-100 text-green-700 rounded-md hover:bg-green-200 transition-colors"
                        title="กู้คืนโฟลเดอร์"
                      >
                        <RefreshCw className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => onPermanentDeleteFolder(folder.id)}
                        className="p-2 bg-red-100 text-red-700 rounded-md hover:bg-red-200 transition-colors"
                        title="ลบถาวร"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          </div>
        )}

        {deletedFiles.length > 0 && (
          <div>
            <h3 className="text-lg font-semibold mb-4">ไฟล์ที่ถูกลบ</h3>
            {viewMode === "grid" ? (
              <DeletedFilesGridView
                files={deletedFiles}
                onRestore={onRestoreFile}
                onPermanentDelete={onPermanentDeleteFile}
              />
            ) : (
              <DeletedFilesListView
                files={deletedFiles}
                onRestore={onRestoreFile}
                onPermanentDelete={onPermanentDeleteFile}
              />
            )}
          </div>
        )}

        {deletedFolders.length === 0 && deletedFiles.length === 0 && (
          <EmptyState message="ไม่มีไฟล์หรือโฟลเดอร์ในถังขยะ" />
        )}
      </>
    )}
  </div>
);

const DeletedFilesGridView = ({ files, onRestore, onPermanentDelete }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
    {files.map(file => (
      <Card key={file.id} className="p-4 hover:shadow-md transition-shadow border-l-4 border-red-500">
        <div className="flex flex-col h-full">
          <div className="flex items-start justify-between w-full mb-3">
            <div className="flex items-center">
              {getFileIcon(file.file_type)}
              <div className="ml-3 overflow-hidden">
                <h3 className="font-medium text-gray-800 text-left truncate">{file.name}</h3>
                <p className="text-xs text-gray-500">{formatFileSize(file.file_size)}</p>
              </div>
            </div>

            <div className="dropdown dropdown-end">
              <label tabIndex={0} className="btn btn-ghost btn-xs">
                <EllipsisVertical className="h-5 w-5 text-gray-500 hover:text-gray-700" />
              </label>
              <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-md w-40">
                <li>
                  <button onClick={() => onRestore(file.id)} className="flex items-center px-3 py-2 hover:bg-gray-100">
                    <RefreshCw className="h-4 w-4 mr-2 text-green-500" />
                    <span>กู้คืนไฟล์</span>
                  </button>
                </li>
                <li>
                  <button onClick={() => onPermanentDelete(file.id)} className="flex items-center px-3 py-2 hover:bg-gray-100">
                    <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                    <span>ลบถาวร</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="text-xs text-gray-500 mb-2">
            <p>ลบเมื่อ: {formatDate(file.deleted_at)}</p>
            <p>อัพโหลด: {formatDate(file.upload_date)}</p>
          </div>
        </div>
      </Card>
    ))}
  </div>
);

const DeletedFilesListView = ({ files, onRestore, onPermanentDelete }) => (
  <Card className="mb-4">
    <div className="divide-y">
      {files.map(file => (
        <div key={file.id} className="flex items-start justify-between p-3 hover:bg-gray-50 border-l-4 border-red-500">
          <div className="flex items-start flex-grow text-start">
            <div className="flex-shrink-0 mr-3">
              {getFileIcon(file.file_type)}
            </div>
            <div className="min-w-0 flex-grow">
              <p className="font-medium text-gray-800 truncate">{file.name}</p>
              <div className="flex flex-wrap text-xs text-gray-500">
                <span className="mr-3">{formatFileSize(file.file_size)}</span>
                <span className="mr-3">ลบเมื่อ: {formatDate(file.deleted_at)}</span>
                <span>อัพโหลด: {formatDate(file.upload_date)}</span>
              </div>
            </div>
          </div>

          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="btn btn-ghost btn-xs">
              <EllipsisVertical className="h-5 w-5 text-gray-500 hover:text-gray-700" />
            </label>
            <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-md w-40">
              <li>
                <button onClick={() => onRestore(file.id)} className="flex items-center px-3 py-2 hover:bg-gray-100">
                  <RefreshCw className="h-4 w-4 mr-2 text-green-500" />
                  <span>กู้คืนไฟล์</span>
                </button>
              </li>
              <li>
                <button onClick={() => onPermanentDelete(file.id)} className="flex items-center px-3 py-2 hover:bg-gray-100">
                  <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                  <span>ลบถาวร</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      ))}
    </div>
  </Card>
);

export default FilesManagementUI;