import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import axios from '../../utils/axiosConfig';
import { Card } from "../ui/card";
import { Plus, Search, Edit2, Trash2, ArrowLeft, ChevronDown, Filter } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import UserModal from '../modals/UserModal';

const MySwal = withReactContent(Swal);

// กำหนดที่แสดงผลใน dropdown ตามแผนก
const departmentMapping = {
  'ทั้งหมด': 'ทั้งหมด',
  'admin': 'Admin',
  'fin': 'Finance',
  'acc': 'Accounting',
  'wh': 'Warehouse',
  'hr': 'Human Resources',
  'it': 'IT'
};

// เพิ่ม Skeleton Loading Components

// Card Row Skeleton สำหรับตารางปกติ
const TableRowSkeleton = () => (
  <div className="flex min-w-full hover:bg-gray-50 animate-pulse">
    <div className="w-1/4 px-6 py-4">
      <div className="h-5 bg-gray-200 rounded w-16"></div>
    </div>
    <div className="w-1/3 px-6 py-4">
      <div className="h-5 bg-gray-200 rounded w-32"></div>
    </div>
    <div className="w-1/4 px-6 py-4">
      <div className="h-5 bg-gray-200 rounded w-20"></div>
    </div>
    <div className="w-1/6 px-6 py-4">
      <div className="flex justify-center space-x-3">
        <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
        <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
      </div>
    </div>
  </div>
);

// Card Skeleton สำหรับมือถือ
const MobileCardSkeleton = () => (
  <div className="bg-white border rounded-lg shadow-sm overflow-hidden animate-pulse">
    <div className="p-4">
      <div className="flex justify-between items-start">
        <div>
          <div className="h-5 bg-gray-200 rounded w-24 mb-2"></div>
          <div className="h-4 bg-gray-200 rounded w-32"></div>
        </div>
        <div className="h-6 w-16 bg-gray-200 rounded-full"></div>
      </div>
      
      <div className="mt-3 flex justify-end space-x-2">
        <div className="h-8 bg-gray-200 rounded w-20"></div>
        <div className="h-8 bg-gray-200 rounded w-16"></div>
      </div>
    </div>
  </div>
);

// ควรเพิ่ม skeleton loading สำหรับตารางผู้ใช้
const UserTableSkeleton = () => (
  <div className="animate-pulse">
    <div className="flex border-b">
      <div className="w-[20%] px-3 py-3">
        <div className="h-5 bg-gray-200 rounded"></div>
      </div>
      <div className="w-[25%] px-3 py-3">
        <div className="h-5 bg-gray-200 rounded"></div>
      </div>
      <div className="w-[20%] px-3 py-3">
        <div className="h-5 bg-gray-200 rounded"></div>
      </div>
      <div className="w-[15%] px-3 py-3">
        <div className="h-5 bg-gray-200 rounded"></div>
      </div>
      <div className="w-[20%] px-3 py-3">
        <div className="h-5 bg-gray-200 rounded"></div>
      </div>
    </div>
    {[...Array(5)].map((_, index) => (
      <div key={index} className="flex border-b">
        <div className="w-[20%] px-3 py-4">
          <div className="flex items-center">
            <div className="h-8 w-8 bg-gray-200 rounded-full mr-3"></div>
            <div className="h-4 bg-gray-200 rounded w-20"></div>
          </div>
        </div>
        <div className="w-[25%] px-3 py-4">
          <div className="h-4 bg-gray-200 rounded"></div>
        </div>
        <div className="w-[20%] px-3 py-4">
          <div className="h-4 bg-gray-200 rounded w-16"></div>
        </div>
        <div className="w-[15%] px-3 py-4">
          <div className="h-6 w-16 bg-gray-200 rounded-full"></div>
        </div>
        <div className="w-[20%] px-3 py-4">
          <div className="flex space-x-2 justify-center">
            <div className="h-6 w-6 bg-gray-200 rounded-full"></div>
            <div className="h-6 w-6 bg-gray-200 rounded-full"></div>
          </div>
        </div>
      </div>
    ))}
  </div>
);

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [formData, setFormData] = useState({
    member_id: '',
    password: '',
    name: '',
    access_level: 'user'
  });
  const [showPassword, setShowPassword] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'member_id', direction: 'ascending' });
  const [selectedOption, setSelectedOption] = useState('เรียงตาม Member ID');
  const [selectedDepartment, setSelectedDepartment] = useState('ทั้งหมด');
  const [isMemberIdDropdownOpen, setIsMemberIdDropdownOpen] = useState(false);
  const [isDepartmentDropdownOpen, setIsDepartmentDropdownOpen] = useState(false);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false); // เพิ่มสำหรับเมนูกรองบนมือถือ
  const [loading, setLoading] = useState(true); // เพิ่ม state สำหรับ loading
  const navigate = useNavigate();

  // เพิ่ม ref สำหรับใช้ตรวจจับการคลิกนอกพื้นที่
  const filterMenuRef = useRef(null);
  const departmentDropdownRef = useRef(null);
  const memberIdDropdownRef = useRef(null);

  // Fetch users from API
  const fetchUsers = useCallback(async () => {
    try {
      setLoading(true); // เริ่มต้น loading
      const token = localStorage.getItem('admin_token');
      if (!token) {
        navigate('/adminlogin');
        return;
      }

      const response = await axios.get('/users');

      if (response.status === 403) {
        navigate('/adminlogin');
        return;
      }

      if (Array.isArray(response.data)) {
        setUsers(response.data);
      } else {
        console.error('Unexpected response data:', response.data);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      // เพิ่ม delay เล็กน้อยเพื่อให้ skeleton แสดงได้นานพอ
      setTimeout(() => {
        setLoading(false);
      }, 700);
    }
  }, [navigate]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  // ปรับปรุงฟังก์ชัน handleSubmit
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // เริ่ม loading เมื่อทำการบันทึก
      setLoading(true);

      // ตรวจสอบว่าต้องการเปลี่ยนรหัสสมาชิกหรือไม่
      if (editingUser && formData.new_member_id) {
        await handleChangeMemberId(editingUser.member_id, formData.new_member_id);
        return;
      }
      
      // สร้าง payload สำหรับส่งไป API
      const payload = {
        ...formData,
        confirm_password: formData.password // เพิ่มการส่ง confirm_password เพื่อให้ตรงกับที่ backend ต้องการ
      };
      
      const url = editingUser
        ? `/users/${editingUser.member_id}`
        : '/users';

      const method = editingUser ? 'put' : 'post';

      const response = await axios[method](url, payload);

      if (response.status >= 200 && response.status < 300) {
        fetchUsers();
        setIsModalOpen(false);
        resetForm();
        MySwal.fire({
          title: 'สำเร็จ!',
          text: 'บันทึกข้อมูลผู้ใช้เรียบร้อยแล้ว.',
          icon: 'success',
          confirmButtonText: 'ตกลง',
          customClass: {
            confirmButton: 'bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded'
          },
          buttonsStyling: false
        });
      } else {
        MySwal.fire({
          title: 'เกิดข้อผิดพลาด!',
          icon: 'error',
          confirmButtonText: 'ตกลง',
          customClass: {
            confirmButton: 'bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded'
          },
          buttonsStyling: false,
          confirmButtonColor: '#3085d6'
        });
      }
    } catch (error) {
      console.error('Error saving user:', error);
      MySwal.fire({
        title: 'เกิดข้อผิดพลาด!',
        text: 'ไม่สามารถบันทึกข้อมูลผู้ใช้ได้.',
        icon: 'error',
        confirmButtonColor: '#3085d6'
      });
    } finally {
      // หลังจากบันทึกเสร็จสิ้นแล้ว
      fetchUsers(); // โหลดข้อมูลใหม่
    }
  };

  // ปรับปรุงฟังก์ชัน handleDelete
  const handleDelete = async (user) => {
    try {
      // ดึงข้อมูลโฟลเดอร์ของผู้ใช้ก่อนลบ
      const folderResponse = await axios.get(`/users/${user.member_id}/folders`);
      
      const userFolders = folderResponse.data.folders || [];
      
      // สร้างข้อความแจ้งเตือนที่แสดงรายละเอียดโฟลเดอร์
      let warningMessage = `คุณกำลังจะลบผู้ใช้ ${user.name} (Member ID : ${user.member_id})`;
      
      if (userFolders.length > 0) {
        warningMessage += `\n\nการลบนี้จะส่งผลให้เกิดการเปลี่ยนแปลงกับ ${userFolders.length} โฟลเดอร์:`;
        const folderList = userFolders.slice(0, 5).map(folder => 
          `• ${folder.name} (${folder.file_count} ไฟล์)`
        ).join('\n');
        
        warningMessage += `\n${folderList}`;
        
        if (userFolders.length > 5) {
          warningMessage += `\n... และอีก ${userFolders.length - 5} โฟลเดอร์`;
        }
      }
      
      // แสดงหน้าต่าง confirm การลบพร้อมรายละเอียด
      MySwal.fire({
        title: 'คุณแน่ใจหรือไม่?',
        html: warningMessage.replace(/\n/g, '<br>'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'ใช่, ลบผู้ใช้นี้!',
        cancelButtonText: 'ยกเลิก'
      }).then(async (result) => {
        if (result.isConfirmed) {
          // โค้ดส่วนลบผู้ใช้เดิมของคุณ
          const response = await axios.delete(`/users/${user.member_id}`);
          
          // จัดการผลลัพธ์การลบเหมือนเดิม
          if (response.status === 200) {
            fetchUsers();
            MySwal.fire({
              title: 'ลบแล้ว!',
              text: 'ผู้ใช้ถูกลบเรียบร้อยแล้ว',
              icon: 'success',
              confirmButtonColor: '#3085d6'
            });
          } else {
            MySwal.fire({
              title: 'เกิดข้อผิดพลาด!',
              text: response.data.message,
              icon: 'error',
              confirmButtonColor: '#3085d6'
            });
          }
        }
      });
    } catch (error) {
      console.error('Error preparing user delete:', error);
      MySwal.fire({
        title: 'เกิดข้อผิดพลาด!',
        text: 'ไม่สามารถโหลดข้อมูลโฟลเดอร์ได้',
        icon: 'error',
        confirmButtonColor: '#3085d6'
      });
    }
  };

  const handleEdit = (user) => {
    setEditingUser(user);
    setFormData({
      member_id: user.member_id,
      password: user.password,
      name: user.name,
      access_level: user.access_level
    });
    setShowPassword(false);
    setIsModalOpen(true);
  };

  const resetForm = () => {
    setEditingUser(null);
    setFormData({
      member_id: '',
      password: '',
      name: '',
      access_level: 'user'
    });
    setShowPassword(false);
  };

  const sortedUsers = React.useMemo(() => {
    let sortableUsers = [...users];
    if (sortConfig !== null) {
      sortableUsers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableUsers;
  }, [users, sortConfig]);


  // แก้ไขฟังก์ชัน handleSortChange ให้ทำงานได้อย่างถูกต้อง
const handleSortChange = (option) => {
  setSelectedOption(option);
  
  // ตรวจสอบประเภทตัวเลือก
  if (option === 'รหัสสมาชิกน้อยไปมาก') {
    setSortConfig({ key: 'member_id', direction: 'ascending' });
  } else if (option === 'รหัสสมาชิกมากไปน้อย') {
    setSortConfig({ key: 'member_id', direction: 'descending' });
  } else if (option === 'A-Z') {
    setSortConfig({ key: 'name', direction: 'ascending' });
  } else if (option === 'Z-A') {
    setSortConfig({ key: 'name', direction: 'descending' });
  } else if (['ทั้งหมด', 'admin', 'fin', 'acc', 'wh', 'hr', 'it'].includes(option)) {
    // ถ้าเป็นตัวเลือกแผนก ให้เรียกใช้ฟังก์ชันกรองแผนก
    handleDepartmentSelection(option);
    return; // จบการทำงานของฟังก์ชัน
  }
  
  // ปิด dropdown และ log การเปลี่ยนแปลง
  setIsMemberIdDropdownOpen(false);
  console.log(`Sort option changed to: ${option}`);
  console.log(`Current sortConfig:`, sortConfig);
}

// ปรับปรุงฟังก์ชัน filteredUsers เพื่อให้กรองได้อย่างถูกต้อง
  const filteredUsers = useMemo(() => {
    // เพิ่มการ log เพื่อตรวจสอบค่า
    console.log(`Filtering users with searchTerm: "${searchTerm}", department: "${selectedDepartment}"`);
    console.log(`Total users before filter: ${sortedUsers.length}`);
    
    const result = sortedUsers.filter(user => {
      // กรองตาม searchTerm (ค้นหาใน member_id และ name)
      const matchesSearchTerm = searchTerm === '' || 
        (user.member_id && user.member_id.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
        (user.name && user.name.toLowerCase().includes(searchTerm.toLowerCase()));
        
      // กรองตาม department (access_level)
      const matchesDepartment = selectedDepartment === 'ทั้งหมด' || user.access_level === selectedDepartment;
      
      return matchesSearchTerm && matchesDepartment;
    });
    
    console.log(`Total users after filter: ${result.length}`);
    return result;
  }, [sortedUsers, searchTerm, selectedDepartment]);

  // เพิ่มฟังก์ชันจัดการการเปลี่ยนรหัสสมาชิก
  const handleChangeMemberId = async (oldMemberId, newMemberId) => {
    if (!newMemberId) return;
    
    try {
      const response = await axios.put(`/users/${oldMemberId}/update-id`, { 
        new_member_id: newMemberId 
      });
      
      if (response.status === 200) {
        fetchUsers();
        setIsModalOpen(false);
        resetForm();
        MySwal.fire({
          title: 'สำเร็จ!',
          text: 'เปลี่ยนรหัสสมาชิกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#3085d6'
        });
      } else {
        MySwal.fire({
          title: 'เกิดข้อผิดพลาด!',
          text: response.data.message || 'ไม่สามารถเปลี่ยนรหัสสมาชิกได้',
          icon: 'error',
          confirmButtonColor: '#3085d6'
        });
      }
    } catch (error) {
      console.error('Error changing member ID:', error);
      MySwal.fire({
        title: 'เกิดข้อผิดพลาด!',
        text: 'ไม่สามารถเปลี่ยนรหัสสมาชิกได้',
        icon: 'error',
        confirmButtonColor: '#3085d6'
      });
    }
  };

  // เพิ่มฟังก์ชันปิด dropdown เมื่อคลิกนอกพื้นที่
  useEffect(() => {
    function handleClickOutside(event) {
      // ปิด dropdown เมื่อคลิกนอกพื้นที่
      if (filterMenuRef.current && !filterMenuRef.current.contains(event.target)) {
        setIsFilterMenuOpen(false);
      }
      
      if (departmentDropdownRef.current && !departmentDropdownRef.current.contains(event.target)) {
        setIsDepartmentDropdownOpen(false);
      }
      
      if (memberIdDropdownRef.current && !memberIdDropdownRef.current.contains(event.target)) {
        setIsMemberIdDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // ฟังก์ชันที่ช่วยให้แสดงตัวเลือกที่เลือกในตัวกรองมือถือ
  const getSelectedFilterText = () => {
    let text = [];
    
    if (selectedOption !== 'เรียงตาม Member ID') {
      text.push(selectedOption);
    }
    
    if (selectedDepartment !== 'ทั้งหมด') {
      text.push(departmentMapping[selectedDepartment]);
    }
    
    return text.length ? text.join(', ') : 'ตัวกรอง';
  }

  // แก้ไขฟังก์ชัน handleDepartmentSelection เพื่อให้การตั้งค่า department ทำงานได้อย่างถูกต้อง
const handleDepartmentSelection = (option) => {
  console.log(`Department filter set to: ${option}`);
  setSelectedDepartment(option);
  
  // ปิด dropdown ทั้งหมด
  setIsMemberIdDropdownOpen(false);
  setIsDepartmentDropdownOpen(false);
  setIsFilterMenuOpen(false);
}

  // เพิ่มฟังก์ชันล้างตัวกรองทั้งหมด
  const clearAllFilters = () => {
    setSearchTerm('');
    setSelectedDepartment('ทั้งหมด');
    setSelectedOption('เรียงตาม Member ID');
    setSortConfig({ key: 'member_id', direction: 'ascending' });
  }

  return (
    <div className="p-4 sm:p-8 bg-gray-50 min-h-screen">
      <div className='flex justify-end items-center'>
        <button
          onClick={() => navigate('/admin/dashboard')}
          className="flex gap-2 bg-none text-gray-600 px-3 py-2 mb-3 sm:px-4 sm:py-2 sm:mb-5 rounded-md hover:text-gray-800 transition-colors"
        >
          <ArrowLeft className="h-5 w-5" />
          <span >ย้อนกลับ</span>
        </button>
      </div>
      <div className="mb-6 sm:mb-8 flex justify-center items-center">
        <div className="text-center">
          <h1 className="text-2xl sm:text-3xl font-bold text-gray-800">จัดการผู้ใช้งาน</h1>
          <p className="text-sm sm:text-base text-gray-600 mt-1 sm:mt-2">เพิ่ม ลบ และแก้ไขข้อมูลผู้ใช้งานระบบ</p>
        </div>
      </div>

      <Card className="p-3 sm:p-6">
        {/* ส่วนค้นหาและปุ่มเพิ่มผู้ใช้ - ปรับให้รองรับการแสดงผลบนมือถือ */}
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-3 sm:gap-0 mb-4 sm:mb-6">
          <div className="relative w-full sm:w-64">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4 sm:h-5 sm:w-5" />
            <input
              type="text"
              placeholder="ค้นหาผู้ใช้..."
              className="pl-10 pr-4 py-2 text-sm sm:text-base w-full border rounded-md focus:ring-gray-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <div className="flex justify-between items-center gap-2">
            {/* ปุ่มตัวกรองสำหรับมือถือ */}
            <div className="sm:hidden relative w-full">
              <button
                onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)}
                className="flex items-center gap-1 bg-white border border-gray-300 text-gray-700 px-3 py-2 rounded-md w-full"
              >
                <Filter className="h-4 w-4" />
                <span className="text-sm truncate">{getSelectedFilterText()}</span>
                <ChevronDown className="h-4 w-4 ml-auto" />
              </button>
              
              {isFilterMenuOpen && (
                <div className="absolute right-0 mt-1 w-64 max-w-[90vw] origin-top-right bg-white border border-gray-200 rounded-md shadow-lg z-10" ref={filterMenuRef}>
                  <div className="px-3 py-2 border-b">
                    <p className="text-sm font-medium text-gray-700">จัดเรียง</p>
                    <div className="mt-2 space-y-1">
                      {['รหัสสมาชิกน้อยไปมาก', 'รหัสสมาชิกมากไปน้อย', 'A-Z', 'Z-A'].map((option) => (
                        <button
                          key={option}
                          onClick={() => {
                            handleSortChange(option);
                            setIsFilterMenuOpen(false);
                          }}
                          className={`text-gray-700 block w-full text-left px-2 py-1 text-sm rounded ${selectedOption === option ? 'bg-blue-100 text-blue-700' : ''}`}
                        >
                          {option}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="px-3 py-2">
                    <p className="text-sm font-medium text-gray-700">ระดับสิทธิ์</p>
                    <div className="mt-2 space-y-1">
                      {['ทั้งหมด', 'admin', 'fin', 'acc', 'wh', 'hr', 'it'].map((option) => (
                        <button
                          key={option}
                          onClick={(e) => {
                            e.stopPropagation(); // เพิ่ม e.stopPropagation() เพื่อป้องกันการปิด dropdown
                            handleDepartmentSelection(option);
                          }}
                          className={`text-gray-700 block w-full text-left px-2 py-1 text-sm rounded ${selectedDepartment === option ? 'bg-blue-100 text-blue-700' : ''}`}
                        >
                          {departmentMapping[option]}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="px-3 py-2 border-t">
                    <button
                      onClick={() => {
                        clearAllFilters();
                        setIsFilterMenuOpen(false);
                      }}
                      className="w-full text-center py-1.5 text-sm text-blue-600 hover:bg-blue-50 rounded"
                    >
                      ล้างตัวกรองทั้งหมด
                    </button>
                  </div>
                </div>
              )}
            </div>

            <button
              onClick={() => {
                resetForm();
                setIsModalOpen(true);
              }}
              className="flex items-center gap-1 sm:gap-2 bg-blue-600 text-white px-3 py-2 rounded-md hover:bg-blue-700 transition-colors"
            >
              <Plus className="h-5 w-4 sm:h-5 sm:w-5"/>
              <span className="hidden sm:inline">เพิ่มผู้ใช้</span>
              <span className="sm:hidden">เพิ่ม</span>
            </button>
          </div>
        </div>

        {/* ส่วนตัวกรองสำหรับหน้าจอขนาดกลางขึ้นไป - ซ่อนในมือถือ */}
        <div className="hidden sm:flex gap-4 mb-6">
          {selectedDepartment === 'ทั้งหมด' && (
            <div className="relative inline-block text-left">
              <button
                type="button"
                className="inline-flex justify-between items-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsMemberIdDropdownOpen(!isMemberIdDropdownOpen);
                  setIsDepartmentDropdownOpen(false);
                }}
              >
                {selectedOption}
                <ChevronDown className="w-4 h-4 ml-2" />
              </button>

              {isMemberIdDropdownOpen && (
                <div
                  className="absolute left-0 w-56 mt-2 origin-top-left bg-white border border-gray-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                  role="menu"
                  ref={memberIdDropdownRef}
                >
                  <div className="py-1">
                    {['รหัสสมาชิกน้อยไปมาก', 'รหัสสมาชิกมากไปน้อย', 'A-Z', 'Z-A'].map((option) => (
                      <button
                        key={option}
                        onClick={() => {
                          handleSortChange(option);
                          setIsMemberIdDropdownOpen(false);
                        }}
                        className={`text-gray-700 block w-full px-4 py-2 text-sm text-left ${selectedOption === option ? 'bg-blue-100 text-blue-700' : 'hover:bg-gray-100'}`}
                        role="menuitem"
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="relative inline-block text-left">
            <button
              type="button"
              className="inline-flex justify-between items-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              onClick={(e) => {
                e.stopPropagation();
                setIsDepartmentDropdownOpen(!isDepartmentDropdownOpen);
                setIsMemberIdDropdownOpen(false);
              }}
            >
              {selectedDepartment === 'ทั้งหมด' ? 'ระดับสิทธิ์' : departmentMapping[selectedDepartment]}
              <ChevronDown className="w-4 h-4 ml-2" />
            </button>

            {isDepartmentDropdownOpen && (
              <div
                className="absolute left-0 w-56 mt-2 origin-top-left bg-white border border-gray-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                role="menu"
                ref={departmentDropdownRef}
              >
                <div className="py-1">
                  {['ทั้งหมด', 'admin', 'fin', 'acc', 'wh', 'hr', 'it'].map((option) => (
                    <button
                      key={option}
                      onClick={(e) => {
                        e.stopPropagation(); // เพิ่ม e.stopPropagation()
                        handleDepartmentSelection(option);
                      }}
                      className={`text-gray-700 block w-full px-4 py-2 text-sm text-left ${selectedDepartment === option ? 'bg-blue-100 text-blue-700' : 'hover:bg-gray-100'}`}
                      role="menuitem"
                    >
                      {departmentMapping[option]}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* ตารางแสดงผลสำหรับหน้าจอขนาดกลางขึ้นไป - แก้ไขโดยใช้ div แทน table */}
        <div className="hidden sm:block overflow-x-auto">
          {/* หัวข้อของตาราง */}
          <div className="bg-gray-50 border-b border-gray-200">
            <div className="flex min-w-full">
              <div className="w-1/4 px-6 py-3 text-left">
                <span className="text-xs font-medium text-gray-500 uppercase tracking-wider">รหัสสมาชิก</span>
              </div>
              <div className="w-1/3 px-6 py-3 text-left">
                <span className="text-xs font-medium text-gray-500 uppercase tracking-wider">ชื่อ</span>
              </div>
              <div className="w-1/4 px-6 py-3 text-left">
                <span className="text-xs font-medium text-gray-500 uppercase tracking-wider">ระดับสิทธิ์</span>
              </div>
              <div className="w-1/6 px-6 py-3 text-center">
                <span className="text-xs font-medium text-gray-500 uppercase tracking-wider">จัดการ</span>
              </div>
            </div>
          </div>
          
          {/* เนื้อหาของตาราง */}
          <div className="bg-white divide-y divide-gray-200 text-left">
            {loading ? (
              // แสดง skeleton ขณะโหลดข้อมูล
              <UserTableSkeleton />
            ) : (
              // แสดงข้อมูลเมื่อโหลดเสร็จ
              filteredUsers.map((user) => (
                <div key={user.member_id} className="flex min-w-full hover:bg-gray-50">
                  <div className="w-1/4 px-6 py-4">
                    <span className="text-sm text-gray-900">{user.member_id}</span>
                  </div>
                  <div className="w-1/3 px-6 py-4">
                    <span className="text-sm text-gray-900">{user.name}</span>
                  </div>
                  <div className="w-1/4 px-6 py-4">
                    <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      user.access_level === 'admin' ? 'bg-purple-100 text-purple-800' : 
                      user.access_level === 'fin' ? 'bg-green-100 text-green-800' :
                      user.access_level === 'acc' ? 'bg-blue-100 text-blue-800' :
                      user.access_level === 'wh' ? 'bg-yellow-100 text-yellow-800' :
                      user.access_level === 'hr' ? 'bg-pink-100 text-pink-800' :
                      user.access_level === 'it' ? 'bg-indigo-100 text-indigo-800' :
                      'bg-gray-100 text-gray-800'
                    }`}>
                      {departmentMapping[user.access_level] || user.access_level}
                    </span>
                  </div>
                  <div className="w-1/6 px-6 py-4">
                    <div className="flex justify-center space-x-3">
                      <button
                        onClick={() => handleEdit(user)}
                        className="text-blue-600 hover:text-blue-900 inline-flex items-center"
                        aria-label="แก้ไข"
                      >
                        <Edit2 className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => handleDelete(user)}
                        className="text-red-600 hover:text-red-900 inline-flex items-center"
                        aria-label="ลบ"
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        
        {/* รายการการ์ดสำหรับมือถือ */}
        <div className="sm:hidden">
          {loading ? (
            // แสดง skeleton บนมือถือ
            <div className="space-y-3">
              {[...Array(5)].map((_, index) => (
                <MobileCardSkeleton key={index} />
              ))}
            </div>
          ) : filteredUsers.length > 0 ? (
            <div className="space-y-3">
              {filteredUsers.map((user) => (
                <div key={user.member_id} className="bg-white border rounded-lg shadow-sm overflow-hidden">
                  <div className="p-4">
                    <div className="flex justify-between items-start">
                      <div>
                        <h3 className="font-medium text-gray-900">{user.name}</h3>
                        <p className="text-sm text-gray-600">รหัสสมาชิก: {user.member_id}</p>
                      </div>
                      <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                        user.access_level === 'admin' ? 'bg-purple-100 text-purple-800' : 
                        user.access_level === 'fin' ? 'bg-green-100 text-green-800' :
                        user.access_level === 'acc' ? 'bg-blue-100 text-blue-800' :
                        user.access_level === 'wh' ? 'bg-yellow-100 text-yellow-800' :
                        user.access_level === 'hr' ? 'bg-pink-100 text-pink-800' :
                        user.access_level === 'it' ? 'bg-indigo-100 text-indigo-800' :
                        'bg-gray-100 text-gray-800'
                      }`}>
                        {departmentMapping[user.access_level] || user.access_level}
                      </span>
                    </div>
                    
                    <div className="mt-3 flex justify-end space-x-2">
                      <button
                        onClick={() => handleEdit(user)}
                        className="inline-flex items-center justify-center px-3 py-1.5 border border-blue-600 text-blue-600 rounded-md hover:bg-blue-50"
                      >
                        <Edit2 className="h-4 w-4 mr-1" />
                        <span className="text-sm">แก้ไข</span>
                      </button>
                      <button
                        onClick={() => handleDelete(user)}
                        className="inline-flex items-center justify-center px-3 py-1.5 border border-red-600 text-red-600 rounded-md hover:bg-red-50"
                      >
                        <Trash2 className="h-4 w-4 mr-1" />
                        <span className="text-sm">ลบ</span>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-8 text-gray-500">
              <p>ไม่พบข้อมูลผู้ใช้งาน</p>
            </div>
          )}
        </div>
        
        {/* แสดงข้อความเมื่อไม่มีข้อมูล */}
        {!loading && filteredUsers.length === 0 && (
          <div className="hidden sm:flex justify-center items-center py-10">
            <div className="text-center">
              <p className="text-gray-500">ไม่พบข้อมูลผู้ใช้งานที่ตรงกับเงื่อนไขที่ค้นหา</p>
              <button 
                onClick={() => {
                  setSearchTerm('');
                  setSelectedDepartment('ทั้งหมด');
                }}
                className="mt-2 text-sm text-blue-600 hover:underline"
              >
                ล้างตัวกรอง
              </button>
            </div>
          </div>
        )}
        {!loading && filteredUsers.length === 0 && (
          <div className="py-8 text-center">
            <div className="text-gray-500 mb-2">ไม่พบข้อมูลผู้ใช้งานที่ตรงกับเงื่อนไข</div>
            <button 
              onClick={clearAllFilters}
              className="px-3 py-1 text-sm text-blue-600 hover:bg-blue-50 rounded"
            >
              ล้างตัวกรอง
            </button>
          </div>
        )}
      </Card>

      <UserModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleSubmit={handleSubmit}
        formData={formData}
        setFormData={setFormData}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
        editingUser={editingUser}
      />
    </div>
  );
};

export default UserManagement;
