import axios from 'axios';

// ใช้ environment variable แทนการ hardcode
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';
console.log("Using API URL:", API_URL); // เพิ่ม log เพื่อตรวจสอบ

// กำหนดค่าพื้นฐานให้ axios
axios.defaults.baseURL = API_URL;
axios.defaults.withCredentials = true; // สำคัญ! ทำให้ส่ง cookies ในทุก request

// ตัวแปรเพื่อป้องกันการเรียก refresh token พร้อมกันหลาย request
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  
  failedQueue = [];
};

// เพิ่มเติม request interceptor
axios.interceptors.request.use(
  async (config) => {
    // ตรวจสอบประเภทของ request ว่าเป็น admin หรือ user
    const isAdminRequest = config.url.includes('/admin') || 
                          localStorage.getItem('currentPath')?.includes('/admin');
    
    const tokenType = isAdminRequest ? 'admin_token' : 'token';
    const expirationKey = isAdminRequest ? 'adminTokenExpiration' : 'tokenExpiration';
    
    const token = localStorage.getItem(tokenType);
    const expiration = localStorage.getItem(expirationKey);
    
    if (token) {
      // ตั้งค่า Authorization header
      config.headers['Authorization'] = `Bearer ${token}`;
      
      // ตรวจสอบว่า token ใกล้หมดอายุหรือยัง
      if (expiration) {
        const expirationTime = new Date(expiration).getTime();
        const currentTime = new Date().getTime();
        const timeLeft = expirationTime - currentTime;
        
        // ถ้าเหลือเวลาน้อยกว่า 3 นาที แต่ยังไม่หมดอายุ ให้ refresh token
        const thirtyMinutesInMs = 3 * 60 * 1000;
        if (timeLeft < thirtyMinutesInMs && timeLeft > 0 && !config.url.includes('refresh_token')) {
          console.log("API_CALL: เหลือเวลา token น้อยกว่า 3 นาที กำลังต่ออายุ...");
          
          try {
            // เรียก refresh token API แบบ synchronous
            const response = await axios.post('/refresh_token', {}, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'x-token-type': isAdminRequest ? 'admin' : 'user'
              }
            });
            
            if (response.data.token) {
              // เก็บ token ใหม่
              localStorage.setItem(tokenType, response.data.token);
              
              // เพิ่มการอัปเดต token expiration ตรงนี้
              try {
                // ถอดรหัส token เพื่อดูวันหมดอายุ
                const newToken = response.data.token;
                const base64Url = newToken.split('.')[1];
                const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                  return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
                
                const decodedToken = JSON.parse(jsonPayload);
                const expirationDate = new Date(decodedToken.exp * 1000);
                
                console.log("API_CALL: Token ใหม่จะหมดอายุเมื่อ:", expirationDate.toLocaleString('th-TH'));
                
                // อัปเดตค่า tokenExpiration
                localStorage.setItem(
                  isAdminRequest ? "adminTokenExpiration" : "tokenExpiration", 
                  expirationDate.toISOString()
                );
                
                // อัปเดตค่า tokenExpirationDisplay
                localStorage.setItem(
                  isAdminRequest ? "adminTokenExpirationDisplay" : "tokenExpirationDisplay", 
                  expirationDate.toLocaleString('th-TH', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                  })
                );
              } catch (err) {
                console.error("API_CALL: เกิดข้อผิดพลาดในการอัปเดต token expiration", err);
              }
              
              // อัปเดต header ใหม่สำหรับ request ปัจจุบัน
              config.headers['Authorization'] = `Bearer ${response.data.token}`;
              console.log("API_CALL: ต่ออายุ token สำเร็จ และอัปเดต header แล้ว");
            }
          } catch (error) {
            console.error("API_CALL: ไม่สามารถต่ออายุ token ได้", error);
          }
        }
      }
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor - จัดการกรณี token หมดอายุ
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    
    console.log("DEBUG: เกิด error:", error.response?.status);
    
    // ถ้าไม่ใช่ error 401 หรือเป็น request ที่ refresh token อยู่แล้ว ให้ reject ไปเลย
    if (!error.response || error.response.status !== 401 || originalRequest._retry) {
      return Promise.reject(error);
    }
    
    // ตรวจสอบว่าเป็น admin request หรือ user request
    const isAdminRequest = originalRequest.url.includes('/admin') || 
                          localStorage.getItem('currentPath')?.includes('/admin');
    
    const tokenType = isAdminRequest ? 'admin_token' : 'token';
    const token = localStorage.getItem(tokenType);
    
    if (!token) {
      const isLoginRequest = originalRequest.url.includes('/login') || originalRequest.url.includes('/adminlogin');
      
      if (!isLoginRequest) {
        window.location.href = isAdminRequest ? '/adminlogin' : '/';
      }
    
      return Promise.reject(error);
    }
    
    
    // เพื่อป้องกันการเรียก refresh token พร้อมกันหลาย request
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      }).then(token => {
        originalRequest.headers['Authorization'] = `Bearer ${token}`;
        return axios(originalRequest);
      }).catch(err => {
        return Promise.reject(err);
      });
    }
    
    originalRequest._retry = true;
    isRefreshing = true;
    
    try {
      console.log("DEBUG: กำลังเรียก refresh token API...");
      // เรียก API refresh token
      const response = await axios.post('/refresh_token', {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-token-type': isAdminRequest ? 'admin' : 'user'
        }
      });
      
      console.log("DEBUG: ได้รับการตอบกลับ:", response.data);
      
      if (response.data.token) {
        const newToken = response.data.token;
        console.log("DEBUG: ได้รับ token ใหม่");
        
        // เก็บ token ใหม่
        localStorage.setItem(tokenType, newToken);
        
        try {
          // ถอดรหัส token เพื่อดูวันหมดอายุ
          const base64Url = newToken.split('.')[1];
          const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
          
          const decodedToken = JSON.parse(jsonPayload);
          const expirationDate = new Date(decodedToken.exp * 1000);
          
          console.log("DEBUG: Token จะหมดอายุเมื่อ:", expirationDate.toLocaleString('th-TH'));
          
          localStorage.setItem(
            isAdminRequest ? "adminTokenExpiration" : "tokenExpiration", 
            expirationDate.toISOString()
          );
          
          localStorage.setItem(
            isAdminRequest ? "adminTokenExpirationDisplay" : "tokenExpirationDisplay", 
            expirationDate.toLocaleString('th-TH', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit'
            })
          );
        } catch (e) {
          console.error("DEBUG: เกิดข้อผิดพลาดในการถอดรหัส token:", e);
        }
        
        // กำหนด header ใหม่สำหรับ request เดิม
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        
        // ประมวลผล queue ของ requests ที่รอ
        processQueue(null, newToken);
        isRefreshing = false;
        
        // ส่ง request เดิมอีกครั้ง
        return axios(originalRequest);
      } else {
        console.error("DEBUG: ไม่สามารถ refresh token ได้");
        processQueue(new Error('Failed to refresh token'));
        localStorage.removeItem(tokenType);
        localStorage.removeItem(isAdminRequest ? "adminTokenExpiration" : "tokenExpiration");
        localStorage.removeItem(isAdminRequest ? "adminTokenExpirationDisplay" : "tokenExpirationDisplay");
        window.location.href = isAdminRequest ? '/adminlogin' : '/';
        return Promise.reject(error);
      }
    } catch (refreshError) {
      console.error("DEBUG: เกิดข้อผิดพลาดในการ refresh token:", refreshError);
      processQueue(refreshError);
      isRefreshing = false;
      localStorage.removeItem(tokenType);
      localStorage.removeItem(isAdminRequest ? "adminTokenExpiration" : "tokenExpiration");
      localStorage.removeItem(isAdminRequest ? "adminTokenExpirationDisplay" : "tokenExpirationDisplay");
      window.location.href = isAdminRequest ? '/adminlogin' : '/';
      return Promise.reject(refreshError);
    }
  }
);

export default axios;