import React, { useState } from "react";
import axios from '../utils/axiosConfig';
import { format } from 'date-fns';
import th from 'date-fns/locale/th';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FiCalendar, FiAlertCircle, FiFileText, FiSend, FiInfo } from 'react-icons/fi';

const MySwal = withReactContent(Swal);

const FormSkeleton = () => (
  <div className="animate-pulse space-y-6">
    <div className="space-y-2">
      <div className="h-5 bg-gray-200 rounded w-24"></div>
      <div className="h-12 bg-gray-200 rounded w-64"></div>
    </div>
    <div className="space-y-2">
      <div className="h-5 bg-gray-200 rounded w-24"></div>
      <div className="h-12 bg-gray-200 rounded w-full"></div>
    </div>
    <div className="space-y-2">
      <div className="h-5 bg-gray-200 rounded w-24"></div>
      <div className="h-32 bg-gray-200 rounded w-full"></div>
    </div>
    <div className="h-12 bg-gray-200 rounded w-full mt-6"></div>
  </div>
);

const Report = () => {
  const [formData, setFormData] = useState({
    date: new Date(),
    subject: '',
    details: ''
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleDateChange = (date) => {
    setFormData(prevState => ({
      ...prevState,
      date: date
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    const token = localStorage.getItem("token");
    
    try {
      let formattedDate;
      if (formData.date) {
        const dateObj = new Date(formData.date);
        formattedDate = format(dateObj, 'yyyy-MM-dd');
      } else {
        formattedDate = format(new Date(), 'yyyy-MM-dd');
      }

      const submissionData = { 
        date: formattedDate,
        title: formData.subject,
        description: formData.details || ''
      };

      console.log('Submitting data:', submissionData);

      const response = await axios({
        method: 'POST',
        url: '/report_by_users',
        data: submissionData,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200 || response.status === 201) {
        setSuccess(true);
        
        MySwal.fire({
          title: 'ส่งรายงานสำเร็จ!',
          text: 'ขอบคุณสำหรับการแจ้งปัญหา ผู้ดูแลระบบจะตรวจสอบและดำเนินการต่อไป',
          icon: 'success',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#0369a1' // สีน้ำเงินเข้ม (blue-600)
        });
        
        setFormData({
          date: new Date(),
          subject: '',
          details: ''
        });
        
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      } else {
        MySwal.fire({
          title: 'เกิดข้อผิดพลาด',
          text: 'ไม่สามารถส่งรายงานปัญหาได้ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#0369a1' // สีน้ำเงินเข้ม (blue-600)
        });
      }
    } catch (error) {
      console.error('Error submitting report:', error);
      
      const errorMessage = error.response?.data?.message || 'ไม่สามารถส่งรายงานปัญหาได้';
      MySwal.fire({
        title: 'เกิดข้อผิดพลาด',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#0369a1' // สีน้ำเงินเข้ม (blue-600)
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-50 min-h-screen py-10 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        {/* Header section - เปลี่ยนสีจากม่วงเป็นน้ำเงิน */}
        <div className="text-center mb-10">
          <div className="inline-flex items-center justify-center h-20 w-20 rounded-full bg-blue-100 mb-6">
            <FiAlertCircle className="h-10 w-10 text-blue-600" />
          </div>
          <h1 className="text-3xl font-bold text-gray-900 mb-3">แจ้งปัญหาการใช้งาน</h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            กรุณากรอกรายละเอียดของปัญหาที่คุณพบ เพื่อให้ทีมงานสามารถช่วยเหลือคุณได้อย่างรวดเร็ว
          </p>
        </div>
        
        {/* Form Card - เปลี่ยนสีจากม่วงเป็นน้ำเงิน */}
        <div className="bg-white rounded-xl shadow-lg overflow-hidden">
          <div className="px-6 py-4 bg-gradient-to-r from-blue-500 to-blue-600 flex items-center">
            <FiFileText className="h-5 w-5 text-white" />
            <h2 className="ml-2 text-xl font-semibold text-white">แบบฟอร์มรายงานปัญหา</h2>
          </div>
          
          <div className="p-6">
            {loading ? (
              <FormSkeleton />
            ) : (
              <form onSubmit={handleSubmit}>
                {/* Date Field - แก้ไขให้ input อยู่ชิดซ้ายเหมือนฟิลด์อื่น */}
                <div className="mb-6">
                  <label className="block text-base font-medium text-gray-800 mb-2 flex items-center">
                    <FiCalendar className="mr-2 text-blue-500" />
                    วันที่พบปัญหา
                  </label>
                  <div className="flex items-center">
                    <div className="relative">
                      <DatePicker
                        selected={formData.date}
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy"
                        locale={th}
                        className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        style={{ minWidth: "240px" }}
                        placeholderText="เลือกวันที่..."
                        required
                      />
                      <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                        <FiCalendar className="text-gray-400" />
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Subject Field - เปลี่ยนสีจากม่วงเป็นน้ำเงิน */}
                <div className="mb-6">
                  <label className="block text-base font-medium text-gray-800 mb-2 flex items-center">
                    <FiFileText className="mr-2 text-blue-500" />
                    หัวข้อปัญหา
                  </label>
                  <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    placeholder="ระบุหัวข้อปัญหาที่พบ..."
                    className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    required
                  />
                </div>

                {/* Details Field - เปลี่ยนสีจากม่วงเป็นน้ำเงิน */}
                <div className="mb-6">
                  <label className="block text-base font-medium text-gray-800 mb-2 flex items-center">
                    <FiFileText className="mr-2 text-blue-500" />
                    รายละเอียดปัญหา
                  </label>
                  <textarea
                    name="details"
                    value={formData.details}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded-lg px-4 py-3 h-36 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="อธิบายรายละเอียดของปัญหาที่พบ เช่น เกิดขึ้นเมื่อไหร่ ทำอย่างไร มีข้อความแจ้งเตือนอะไรหรือไม่..."
                    required
                  />
                </div>
                
                {/* Submit Button - เปลี่ยนสีจากม่วงเป็นน้ำเงิน */}
                <div className="mt-8">
                  <button
                    type="submit"
                    disabled={loading}
                    className={`w-full bg-gradient-to-r from-blue-500 to-blue-600 text-white px-6 py-3 rounded-lg text-base font-medium 
                              flex items-center justify-center gap-2 shadow-sm transition-all duration-300
                              ${loading ? 'opacity-70 cursor-not-allowed' : 'hover:from-blue-600 hover:to-blue-700'}`}
                  >
                    {loading ? (
                      <>
                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <span>กำลังส่งรายงาน...</span>
                      </>
                    ) : (
                      <>
                        <FiSend className="h-5 w-5" />
                        <span>ส่งรายงานปัญหา</span>
                      </>
                    )}
                  </button>
                </div>
                
                {/* Success Message - เพิ่มการเคลื่อนไหว */}
                {success && (
                  <div className="mt-4 bg-green-50 border border-green-200 text-green-700 px-4 py-3 rounded-lg flex items-center animate-fadeIn">
                    <svg className="h-5 w-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    รายงานปัญหาถูกส่งเรียบร้อยแล้ว
                  </div>
                )}
              </form>
            )}
          </div>
        </div>
        

      </div>
    </div>
  );
};

export default Report;
