import React, { useState, useEffect } from "react";
import LoginCover from "../../img/Login-cover2.jpg";
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import activityLogger from '../../utils/activityLogger';
import axios from '../../utils/axiosConfig';
import { LogIn, User, Lock, Eye, EyeOff, AlertCircle, Save, Check } from "lucide-react";

export default function LoginPage() {
  const [memberId, setMemberId] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  // โหลดรหัสสมาชิกที่บันทึกไว้เมื่อเริ่มต้น
  useEffect(() => {
    const savedMemberId = localStorage.getItem("rememberedMemberId");
    if (savedMemberId) {
      setMemberId(savedMemberId);
      setRememberMe(true);
    }
  }, []);

  // เช็คว่ามี token ใน localStorage หรือไม่
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        console.log("ระดับสิทธิ์ของผู้ใช้:", decodedToken.access_level);
        navigate('/home');
      } catch (error) {
        console.error("Token ไม่ถูกต้อง:", error);
        localStorage.removeItem("token");
      }
    }
  }, [navigate]);

  // ฟังก์ชันจัดการเข้าสู่ระบบ
  const handleLogin = async (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);

    // บันทึกหรือลบรหัสสมาชิกตามการเลือก "จำรหัสสมาชิก"
    if (rememberMe) {
      localStorage.setItem("rememberedMemberId", memberId);
    } else {
      localStorage.removeItem("rememberedMemberId");
    }

    try {
      const response = await axios.post('/login', {
        member_id: memberId,
        password: password
      });

      const data = response.data;
      
      setMessage("🎉 เข้าสู่ระบบสำเร็จ!");
      localStorage.setItem("token", data.token);
      activityLogger.login(data.name || memberId, false);
      localStorage.setItem("name", data.name);

      // ตั้งค่า token expiration 
      const expirationDate = new Date();
      expirationDate.setMinutes(expirationDate.getMinutes() + 1440);
      localStorage.setItem("tokenExpiration", expirationDate.toISOString());
      localStorage.setItem("tokenExpirationDisplay", expirationDate.toLocaleString('th-TH', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }));

      // Decode token และเก็บข้อมูลผู้ใช้
      const decodedToken = jwtDecode(data.token);
      const currentUser = {
        id: decodedToken.id || decodedToken.user_id,
        member_id: decodedToken.member_id,
        name: decodedToken.name,
        access_level: decodedToken.access_level
      };
      
      localStorage.setItem("currentUser", JSON.stringify(currentUser));

      if (data.currentUser) {
        localStorage.setItem("currentUser", JSON.stringify(data.currentUser));
      }

      // นำทางไปยังหน้าที่กำหนด
      navigate(data.redirectTo || '/home');
      
    } catch (error) {
      console.error("เกิดข้อผิดพลาด:", error);
      
      if (error.response && error.response.data) {
        setMessage(error.response.data.message || "❌ เกิดข้อผิดพลาดในการเข้าสู่ระบบ");
        
        if (error.response.data.redirect) {
          navigate(error.response.data.redirect);
        }
      } else if (error.request) {
        setMessage("❌ ไม่สามารถเชื่อมต่อเซิร์ฟเวอร์ได้");
      } else {
        setMessage("❌ เกิดข้อผิดพลาดที่ไม่ทราบสาเหตุ");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-screen relative bg-gray-50 dark:bg-gray-900">
      {/* พื้นหลังสำหรับมือถือ */}
      <div
        className="absolute inset-0 bg-cover bg-center lg:hidden z-0"
        style={{ backgroundImage: `url(${LoginCover})` }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-60"></div>
      </div>

      {/* ส่วนรูปภาพแสดงบนเดสก์ท็อปเท่านั้น */}
      <div
        className="w-1/2 bg-cover bg-center  lg:block hidden"
        style={{ backgroundImage: `url(${LoginCover})` }}
      ></div>

      {/* ส่วนฟอร์มล็อกอิน */}
      <div className="w-full lg:w-1/2 flex items-center justify-center z-10 relative px-4 sm:px-6">
        <div className="w-full max-w-sm sm:max-w-md bg-white dark:bg-slate-800 p-8 rounded-xl shadow-xl backdrop-blur-sm mx-auto transition-all duration-300 hover:shadow-2xl">
          <div className="text-center mb-8">
            <h2 className="text-2xl font-bold text-center text-gray-800 dark:text-white">ยินดีต้อนรับ</h2>
            <p className="text-gray-500 dark:text-gray-300 mt-1">กรุณาลงชื่อเข้าสู่ระบบ</p>
          </div>

          {/* ข้อความแจ้งเตือน */}
          {message && (
            <div
              className={`mb-6 p-3 text-sm rounded-lg flex items-center gap-2 animate-fadeIn ${
                message.includes("สำเร็จ")
                  ? "bg-green-100 text-green-700 border border-green-200"
                  : "bg-red-50 text-red-600 border border-red-200"
              }`}
            >
              <AlertCircle className="h-5 w-5 flex-shrink-0" />
              <span>{message}</span>
            </div>
          )}

          <form onSubmit={handleLogin} className="space-y-5">
            <div className="space-y-2">
              <label className="block text-gray-700 dark:text-gray-300 text-sm font-medium">
                รหัสสมาชิก
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <User className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  className="w-full pl-10 pr-3 py-3 border border-gray-300 dark:border-gray-600 rounded-lg text-sm
                           bg-white dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:text-white
                           transition-all duration-200"
                  placeholder="กรอกรหัสสมาชิก"
                  value={memberId}
                  onChange={(e) => setMemberId(e.target.value)}
                  required
                  autoFocus
                />
              </div>
            </div>
            
            <div className="space-y-2">
              <label className="block text-gray-700 dark:text-gray-300 text-sm font-medium">
                รหัสผ่าน
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="w-full pl-10 pr-10 py-3 border border-gray-300 dark:border-gray-600 rounded-lg text-sm
                           bg-white dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:text-white
                           transition-all duration-200"
                  placeholder="กรอกรหัสผ่าน"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 
                             dark:hover:text-gray-300 transition-colors"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                  aria-label={passwordVisible ? "ซ่อนรหัสผ่าน" : "แสดงรหัสผ่าน"}
                >
                  {passwordVisible ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                </button>
              </div>
            </div>
            
            {/* จดจำรหัสสมาชิก - ปรับปรุงใหม่ */}
            <div className="flex items-center">
              <div 
                className="flex items-center cursor-pointer mb-5" 
                onClick={() => setRememberMe(!rememberMe)}
              >
                <div className={`h-5 w-5 flex items-center justify-center rounded border mr-2 
                               ${rememberMe 
                                 ? 'bg-blue-500 border-blue-500' 
                                 : 'bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600'}`}
                >
                  {rememberMe && <Check className="h-3 w-3 text-white" />}
                </div>
                <label className="text-sm text-gray-700 dark:text-gray-300 cursor-pointer select-none">
                จดจำรหัสสมาชิก
                </label>
              </div>
            </div>
            
            <button
              type="submit"
              disabled={loading}
              className="w-full bg-blue-600 text-white py-3 px-4 mt-3 rounded-lg hover:bg-blue-700 
                      focus:ring-4 focus:ring-blue-300 transition-all duration-300 text-sm font-medium
                      flex items-center justify-center gap-2 disabled:opacity-70 disabled:cursor-not-allowed
                      hover:shadow-md"
            >
              {loading ? (
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              ) : (
                <>
                  <LogIn className="h-5 w-5" />
                  เข้าสู่ระบบ
                </>
              )}
            </button>
          </form>
          
          <div className="mt-8 text-center">
            <p className="text-sm text-gray-500 dark:text-gray-400">
              © {new Date().getFullYear()} โต เจริญพร - Employee Portal
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
